import React from "react";
import { useParams } from "react-router-dom";
import { translate } from "../languages";
import { API_URL, DESIGNER_EMAIL, S3_URL } from "../consts";

export default function SoldFlyerOrderForm() {
  const { language } = useParams();
  const [form, setForm] = React.useState<{ [key: string]: any }>({
    SUBJECT: "Sold Flyer Order Form",
  });
  const [status, setStatus] = React.useState("");

  React.useEffect(() => {
    if (
      form["AGENT NAME"] &&
      form["PROPERTY ADDRESS"] &&
      form["PROPERTY TYPE"] &&
      (form["PROPERTY TYPE"] === "Condo/Condo townhouse" ||
        (form["Farming"] &&
          (form["Farming"] === "No" ||
            (form["Name Card"] &&
              (form["Name Card"] === "No" ||
                (form["NAME CARD QUANTITIES"] &&
                  form["FARMING AGENT NAME 1"])))) &&
          (form["Farming"] === "No" ||
            (form["Blank"] &&
              (form["Blank"] === "No" || form["BLANK QUANTITIES"]))))) &&
      form["STICKER"] &&
      form["PROPERTY PRICE ON FLYER"] &&
      form["ENGLISH DESCRIPTION FROM MLS FORM (OR CHINESE IF APPLICABLE)"] &&
      form["CHOOSING CANADA POST TO DELIVERY"] &&
      (form["CHOOSING CANADA POST TO DELIVERY"] === "No" ||
        (form["FLYER VERSIONS FOR CANADA POST DELIVERY"] &&
          (form["FLYER VERSIONS FOR CANADA POST DELIVERY"] === "Blank" ||
            form["DELIVERY AGENT NAME 1"]) &&
          form["POST CODE ( INITIAL 3 CHARACTORS)"] &&
          form["SECOND CODE I"] &&
          form[
            "ORDER QUANTITY ( $0.15+HST PER PIECE, STARTING FROM APRIL 25, 2022)"
          ] &&
          form["FILE"]))
    ) {
      setStatus("sendable");
    } else {
      setStatus("");
    }
  }, [form]);

  return (
    <>
      <div className="paper">
        <div className="tag text-capitalize">
          {translate("Sold Flyer Order Form", language)}
        </div>
      </div>
      <div className="paper d-flex flex-column gap-10">
        <div className="row w-100">
          <div className="col col-12 col-lg-6 p-3">
            <label className="form-label">
              {translate("AGENT NAME*", language)}
            </label>
            <input
              className="form-control"
              value={form["AGENT NAME"] || ""}
              onChange={(e) => {
                setForm({ ...form, "AGENT NAME": e.target.value });
              }}
            />
          </div>
          <div className="col col-12 col-lg-6 p-3"></div>
          <div className="col col-12 col-lg-6 p-3">
            <label className="form-label">
              {translate("PROPERTY ADDRESS*", language)}
            </label>
            <input
              className="form-control"
              value={form["PROPERTY ADDRESS"] || ""}
              onChange={(e) => {
                setForm({ ...form, "PROPERTY ADDRESS": e.target.value });
              }}
            />
          </div>
          <div className="col col-12 col-lg-6 p-3">
            <label className="form-label">
              {translate("PROPERTY TYPE*", language)}
            </label>
            <div className="row w-100">
              <div className="col col-12 col-lg-6 p-3">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    checked={form["PROPERTY TYPE"] === "Condo/Condo townhouse"}
                    onChange={() => {
                      setForm({
                        ...form,
                        "PROPERTY TYPE": "Condo/Condo townhouse",
                        Farming: undefined,
                      });
                    }}
                  />
                  <label className="form-check-label">
                    {translate("Condo/Condo townhouse", language)}
                  </label>
                </div>
              </div>
              <div className="col col-12 col-lg-6 p-3">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    checked={form["PROPERTY TYPE"] === "Freehold"}
                    onChange={() => {
                      setForm({
                        ...form,
                        "PROPERTY TYPE": "Freehold",
                        Farming: "No",
                      });
                    }}
                  />
                  <label className="form-check-label">
                    {translate("Freehold", language)}
                  </label>
                </div>
              </div>
            </div>
          </div>
          {form["PROPERTY TYPE"] === "Freehold" && (
            <>
              <div className="col col-12 p-3">
                <label className="switch align-middle">
                  <input
                    type="checkbox"
                    onChange={(e) => {
                      setForm({
                        ...form,
                        Farming: e.target.checked ? "Yes" : "No",
                        "Name Card": "No",
                        Blank: "No",
                        "NAME CARD QUANTITIES": undefined,
                        "FARMING AGENT NAME 1": undefined,
                        "FARMING AGENT NAME 2": undefined,
                        "BLANK QUANTITIES": undefined,
                      });
                    }}
                  />
                  <span className="slider round"></span>
                </label>
                <label className="form-label">
                  {translate("Farming", language)}
                </label>
              </div>
              {form["Farming"] === "Yes" && (
                <>
                  <div className="col col-12 p-3">
                    {translate("FLYER VERSIONS FOR FARMING*", language)}
                  </div>
                  <div className="col col-12 col-lg-6 p-3">
                    <label className="switch align-middle">
                      <input
                        type="checkbox"
                        onChange={(e) => {
                          setForm({
                            ...form,
                            "Name Card": e.target.checked ? "Yes" : "No",
                            "NAME CARD QUANTITIES": undefined,
                            "FARMING AGENT NAME 1": undefined,
                            "FARMING AGENT NAME 2": undefined,
                          });
                        }}
                      />
                      <span className="slider round"></span>
                    </label>
                    <label className="form-label">
                      {translate("Name Card", language)}
                    </label>
                  </div>
                  <div className="col col-12 col-lg-6 p-3">
                    {form["Name Card"] === "Yes" && (
                      <input
                        className="form-control"
                        value={form["NAME CARD QUANTITIES"] || ""}
                        placeholder={translate(
                          "NAME CARD QUANTITIES",
                          language
                        )}
                        onChange={(e) => {
                          setForm({
                            ...form,
                            "NAME CARD QUANTITIES": e.target.value,
                          });
                        }}
                      />
                    )}
                  </div>
                  {form["Name Card"] === "Yes" && (
                    <>
                      <div className="col col-12 col-lg-6 p-3">
                        <label className="form-label">
                          {translate("AGENT NAME 1*", language)}
                        </label>
                        <input
                          className="form-control"
                          value={form["FARMING AGENT NAME 1"] || ""}
                          onChange={(e) => {
                            setForm({
                              ...form,
                              "FARMING AGENT NAME 1": e.target.value,
                            });
                          }}
                        />
                      </div>
                      <div className="col col-12 col-lg-6 p-3">
                        <label className="form-label">
                          {translate("AGENT NAME 2", language)}
                        </label>
                        <input
                          className="form-control"
                          value={form["FARMING AGENT NAME 2"] || ""}
                          onChange={(e) => {
                            setForm({
                              ...form,
                              "FARMING AGENT NAME 2": e.target.value,
                            });
                          }}
                        />
                      </div>
                    </>
                  )}
                  <div className="col col-12 col-lg-6 p-3">
                    <label className="switch align-middle">
                      <input
                        type="checkbox"
                        onChange={(e) => {
                          setForm({
                            ...form,
                            Blank: e.target.checked ? "Yes" : "No",
                            "BLANK QUANTITIES": undefined,
                          });
                        }}
                      />
                      <span className="slider round"></span>
                    </label>
                    <label className="form-label">
                      {translate("Blank", language)}
                    </label>
                  </div>
                  <div className="col col-12 col-lg-6 p-3">
                    {form["Blank"] === "Yes" && (
                      <input
                        className="form-control"
                        value={form["BLANK QUANTITIES"] || ""}
                        placeholder={translate("BLANK QUANTITIES", language)}
                        onChange={(e) => {
                          setForm({
                            ...form,
                            "BLANK QUANTITIES": e.target.value,
                          });
                        }}
                      />
                    )}
                  </div>
                </>
              )}
            </>
          )}
          <div className="col col-12 col-lg-6 p-3">
            <label className="form-label">
              {translate("STICKER*", language)}
            </label>
            <input
              maxLength={30}
              className="form-control"
              value={form["STICKER"] || ""}
              onChange={(e) => {
                setForm({ ...form, STICKER: e.target.value });
              }}
            />
            <div className="text-black-50 fs-14">0 of 30 max characters</div>
            <div className="text-decoration-underline text-black-50 fs-14">
              e.g. SOLD, SOLD OVER ASKING etc.
            </div>
          </div>
          <div className="col col-12 col-lg-6 p-3">
            <label className="form-label">
              {translate("PROPERTY PRICE ON FLYER*", language)}
            </label>
            <input
              maxLength={30}
              className="form-control"
              value={form["PROPERTY PRICE ON FLYER"] || ""}
              onChange={(e) => {
                setForm({ ...form, "PROPERTY PRICE ON FLYER": e.target.value });
              }}
            />
            <div className="text-decoration-underline text-black-50 fs-14">
              Can not be Revised (if you wish to add the wording "Asking Price"
              or "Sold Price" in front of this price, please advise our designer
            </div>
          </div>
          <div className="col col-12 p-3">
            <label className="form-label">
              {translate(
                "ENGLISH DESCRIPTION FROM MLS FORM (OR CHINESE IF APPLICABLE)*",
                language
              )}
            </label>
            <textarea
              className="form-control"
              value={
                form[
                  "ENGLISH DESCRIPTION FROM MLS FORM (OR CHINESE IF APPLICABLE)"
                ] || ""
              }
              onChange={(e) => {
                setForm({
                  ...form,
                  "ENGLISH DESCRIPTION FROM MLS FORM (OR CHINESE IF APPLICABLE)":
                    e.target.value,
                });
              }}
            />
          </div>
          <div className="col col-12 col-lg-6 p-3">
            <label className="form-label">
              {translate(
                "EXTRA FLYERS FOR NAMECARD( $85 PLUS HST EVERY BOX (500 PIECE))",
                language
              )}
            </label>
            <select
              className="form-select"
              value={
                form[
                  "EXTRA FLYERS FOR NAMECARD( $85 PLUS HST EVERY BOX (500 PIECE))"
                ] || ""
              }
              onChange={(e) => {
                setForm({
                  ...form,
                  "EXTRA FLYERS FOR NAMECARD( $85 PLUS HST EVERY BOX (500 PIECE))":
                    e.target.value,
                });
              }}
            >
              <option value="" disabled></option>
              <option value="0">0</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
              <option value="6">6</option>
              <option value="7">7</option>
              <option value="8">8</option>
              <option value="9">9</option>
              <option value="10">10</option>
            </select>
          </div>
          <div className="col col-12 col-lg-6 p-3">
            <label className="form-label">
              {translate(
                "EXTRA FLYERS FOR BLANK( $85 PLUS HST EVERY BOX (500 PIECE))",
                language
              )}
            </label>
            <select
              className="form-select"
              value={
                form[
                  "EXTRA FLYERS FOR BLANK( $85 PLUS HST EVERY BOX (500 PIECE))"
                ] || ""
              }
              onChange={(e) => {
                setForm({
                  ...form,
                  "EXTRA FLYERS FOR BLANK( $85 PLUS HST EVERY BOX (500 PIECE))":
                    e.target.value,
                });
              }}
            >
              <option value="" disabled></option>
              <option value="0">0</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
              <option value="6">6</option>
              <option value="7">7</option>
              <option value="8">8</option>
              <option value="9">9</option>
              <option value="10">10</option>
            </select>
          </div>
          <div className="col col-12 col-lg-6 p-3">
            <label className="form-label">
              {translate("CHOOSING CANADA POST TO DELIVERY*", language)}
            </label>
            <div className="row w-100">
              <div className="col col-12 col-lg-6 p-3">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    checked={form["CHOOSING CANADA POST TO DELIVERY"] === "Yes"}
                    onChange={() => {
                      setForm({
                        ...form,
                        "CHOOSING CANADA POST TO DELIVERY": "Yes",
                      });
                    }}
                  />
                  <label className="form-check-label">
                    {translate("Yes", language)}
                  </label>
                </div>
              </div>
              <div className="col col-12 col-lg-6 p-3">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    checked={form["CHOOSING CANADA POST TO DELIVERY"] === "No"}
                    onChange={() => {
                      setForm({
                        ...form,
                        "CHOOSING CANADA POST TO DELIVERY": "No",
                        "FLYER VERSIONS FOR CANADA POST DELIVERY": undefined,
                        "POST CODE ( INITIAL 3 CHARACTORS)": undefined,
                        "SECOND CODE I": undefined,
                        "SECOND CODE II": undefined,
                        "ORDER QUANTITY ( $0.15+HST PER PIECE, STARTING FROM APRIL 25, 2022)":
                          undefined,
                        FILE: undefined,
                      });
                    }}
                  />
                  <label className="form-check-label">
                    {translate("No", language)}
                  </label>
                </div>
              </div>
            </div>
          </div>
          {form["CHOOSING CANADA POST TO DELIVERY"] === "Yes" && (
            <>
              <div className="col col-12 col-lg-6 p-3">
                <label className="form-label">
                  {translate(
                    "FLYER VERSIONS FOR CANADA POST DELIVERY*",
                    language
                  )}
                </label>
                <div className="row w-100">
                  <div className="col col-12 col-lg-6 p-3">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        checked={
                          form["FLYER VERSIONS FOR CANADA POST DELIVERY"] ===
                          "Name Card"
                        }
                        onChange={() => {
                          setForm({
                            ...form,
                            "FLYER VERSIONS FOR CANADA POST DELIVERY":
                              "Name Card",
                          });
                        }}
                      />
                      <label className="form-check-label">
                        {translate("Name Card", language)}
                      </label>
                    </div>
                  </div>
                  <div className="col col-12 col-lg-6 p-3">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        checked={
                          form["FLYER VERSIONS FOR CANADA POST DELIVERY"] ===
                          "Blank"
                        }
                        onChange={() => {
                          setForm({
                            ...form,
                            "FLYER VERSIONS FOR CANADA POST DELIVERY": "Blank",
                            "DELIVERY AGENT NAME 1": undefined,
                            "DELIVERY AGENT NAME 2": undefined,
                          });
                        }}
                      />
                      <label className="form-check-label">
                        {translate("Blank", language)}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              {form["FLYER VERSIONS FOR CANADA POST DELIVERY"] ===
                "Name Card" && (
                <>
                  <div className="col col-12 col-lg-6 p-3">
                    <label className="form-label">
                      {translate("AGENT NAME 1*", language)}
                    </label>
                    <input
                      className="form-control"
                      value={form["DELIVERY AGENT NAME 1"] || ""}
                      onChange={(e) => {
                        setForm({
                          ...form,
                          "DELIVERY AGENT NAME 1": e.target.value,
                        });
                      }}
                    />
                  </div>
                  <div className="col col-12 col-lg-6 p-3">
                    <label className="form-label">
                      {translate("AGENT NAME 2", language)}
                    </label>
                    <input
                      className="form-control"
                      value={form["DELIVERY AGENT NAME 2"] || ""}
                      onChange={(e) => {
                        setForm({
                          ...form,
                          "DELIVERY AGENT NAME 2": e.target.value,
                        });
                      }}
                    />
                  </div>
                </>
              )}
              <div className="col col-12 col-lg-4 p-3">
                <label className="form-label">
                  {translate("POST CODE ( INITIAL 3 CHARACTORS)*", language)}
                </label>
                <input
                  className="form-control"
                  value={form["POST CODE ( INITIAL 3 CHARACTORS)"] || ""}
                  placeholder="e.g. M1L"
                  onChange={(e) => {
                    setForm({
                      ...form,
                      "POST CODE ( INITIAL 3 CHARACTORS)": e.target.value,
                    });
                  }}
                />
              </div>
              <div className="col col-12 col-lg-4 p-3">
                <label className="form-label">
                  {translate("SECOND CODE I*", language)}
                </label>
                <input
                  className="form-control"
                  value={form["SECOND CODE I"] || ""}
                  placeholder="e.g. SS003"
                  onChange={(e) => {
                    setForm({
                      ...form,
                      "SECOND CODE I": e.target.value,
                    });
                  }}
                />
              </div>
              <div className="col col-12 col-lg-4 p-3">
                <label className="form-label">
                  {translate("SECOND CODE II", language)}
                </label>
                <input
                  className="form-control"
                  value={form["SECOND CODE II"] || ""}
                  placeholder="e.g. SS003"
                  onChange={(e) => {
                    setForm({
                      ...form,
                      "SECOND CODE II": e.target.value,
                    });
                  }}
                />
              </div>
              <div className="col col-12 col-lg-6 p-3">
                <label className="form-label">
                  {translate(
                    "ORDER QUANTITY ( $0.15+HST PER PIECE, STARTING FROM APRIL 25, 2022)*",
                    language
                  )}
                </label>
                <input
                  className="form-control"
                  value={
                    form[
                      "ORDER QUANTITY ( $0.15+HST PER PIECE, STARTING FROM APRIL 25, 2022)"
                    ] || ""
                  }
                  onChange={(e) => {
                    setForm({
                      ...form,
                      "ORDER QUANTITY ( $0.15+HST PER PIECE, STARTING FROM APRIL 25, 2022)":
                        e.target.value,
                    });
                  }}
                />
              </div>
              <div className="col col-12 col-lg-6 p-3">
                <label className="form-label">
                  {translate("FILE*", language)}
                </label>
                <input
                  className="form-control"
                  type="file"
                  accept="image/*, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                  onChange={(e) => {
                    if (e.target.files?.length === 1) {
                      const fileReader = new FileReader();
                      fileReader.onloadend = (e) => {
                        if (e.target?.result) {
                          setForm({
                            ...form,
                            FILE: e.target.result as string,
                          });
                        }
                      };
                      fileReader.readAsDataURL(e.target.files[0]);
                    }
                  }}
                />
              </div>
              <div className="col col-12 p-3">
                SAMPLE
                <img
                  src={`${S3_URL}Canada-Post-Route-01.jpg`}
                  alt=""
                  style={{ width: "100%" }}
                />
              </div>
              <div className="col col-12 col-lg-6 p-3">
                TUTORIAL VIDEO
                <iframe
                  title="TUTORIAL VIDEO"
                  src="https://www.youtube.com/embed/E8Jqv7VrVNg"
                  style={{ width: "100%", height: 315 }}
                ></iframe>
              </div>
              <div className="col col-12 col-lg-6 p-3 d-flex flex-column">
                CORRECT SAMPLE
                <img
                  src={`${S3_URL}Canada-Post-Route-02.jpg`}
                  alt=""
                  style={{ width: "100%" }}
                />
                INCORRECT SAMPLE
                <img
                  src={`${S3_URL}Canada-Post-Route-03.jpg`}
                  alt=""
                  style={{ width: "100%" }}
                />
              </div>
            </>
          )}
          <div className="col col-12 p-3">
            {status === "" && (
              <button
                className="btn-solid"
                style={{ backgroundColor: "#000000" }}
                disabled
              >
                Submit
              </button>
            )}
            {status === "sendable" && (
              <button
                className="btn-solid"
                onClick={() => {
                  setStatus("sending");
                  const to = DESIGNER_EMAIL;
                  const from = "hr@nustreamtoronto.com";
                  const cc = "admin@nustreamtoronto.com";
                  const subject = form["SUBJECT"];
                  const text = JSON.stringify(form);
                  let html =
                    '<table style="width:100%;border: 1px solid;border-collapse: collapse;">';

                  const formProperties = [
                    "SUBJECT",
                    "AGENT NAME",
                    "PROPERTY ADDRESS",
                    "PROPERTY TYPE",
                    "Farming",
                    "Name Card",
                    "NAME CARD QUANTITIES",
                    "FARMING AGENT NAME 1",
                    "FARMING AGENT NAME 2",
                    "Blank",
                    "BLANK QUANTITIES",
                    "STICKER",
                    "PROPERTY PRICE ON FLYER",
                    "ENGLISH DESCRIPTION FROM MLS FORM (OR CHINESE IF APPLICABLE)",
                    "EXTRA FLYERS FOR NAMECARD( $85 PLUS HST EVERY BOX (500 PIECE))",
                    "EXTRA FLYERS FOR BLANK( $85 PLUS HST EVERY BOX (500 PIECE))",
                    "CHOOSING CANADA POST TO DELIVERY",
                    "FLYER VERSIONS FOR CANADA POST DELIVERY",
                    "DELIVERY AGENT NAME 1",
                    "DELIVERY AGENT NAME 2",
                    "POST CODE ( INITIAL 3 CHARACTORS)",
                    "SECOND CODE I",
                    "SECOND CODE II",
                    "ORDER QUANTITY ( $0.15+HST PER PIECE, STARTING FROM APRIL 25, 2022)",
                    "FILE",
                  ];

                  for (const formProperty of formProperties) {
                    if (form[formProperty]) {
                      html =
                        html +
                        '<tr><td style="width:40%;border: 1px solid;border-collapse: collapse;">' +
                        formProperty +
                        '</td><td style="width:60%;border: 1px solid;border-collapse: collapse;">' +
                        form[formProperty] +
                        "</td></tr>";
                    }
                  }

                  html = html + "</table>";
                  fetch(`${API_URL}mail`, {
                    method: "POST",
                    headers: {
                      "Content-Type": "application/json",
                      userName: localStorage.getItem("username") || "",
                    },
                    body: JSON.stringify({ from, to, cc, subject, text, html }),
                  })
                    .then((res) => res.json())
                    .then((json) => {
                      if (json.error) {
                        if (json.error.message) alert(json.error.message);
                        setStatus("failed");
                      } else {
                        setStatus("sent");
                      }
                    });
                }}
              >
                {translate("Submit", language)}
              </button>
            )}
            {status === "sending" && (
              <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            )}
            {status === "sent" && (
              <div className="alert alert-primary" role="alert">
                {translate("Form submited", language)}
              </div>
            )}
            {status === "failed" && (
              <div className="alert alert-danger" role="alert">
                {translate("Form submit failed, please try later!", language)}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
