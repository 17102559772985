import React from "react";
import { useParams } from "react-router-dom";
import Layout from "../components/Layout";
import { translate } from "../languages";
import { API_URL, S3_URL } from "../consts";
import { RealtorType } from "../types";

export default function Login() {
  const { language } = useParams();
  const [email, setEmail] = React.useState("");
  const [userCode, setUserCode] = React.useState("");

  return (
    <Layout>
      <>
        <div
          className="banner"
          style={{ backgroundImage: `url(${S3_URL}banner.jpg)` }}
        >
          {translate(
            "LOGIN - NU STREAM REALTY (TORONTO) INC., BROKERAGE",
            language
          )}
        </div>
        <div
          className="w-50"
          style={{ marginLeft: "auto", marginRight: "auto", minWidth: 300 }}
        >
          <div className="paper">
            <div className="tag">
              {translate("LOGIN INTO YOUR ACCOUNT", language)}
            </div>
          </div>
          <div className="paper d-flex flex-column gap-10">
            <input
              className="form-control"
              placeholder="Email"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
            <input
              type="password"
              className="form-control"
              placeholder="Password"
              value={userCode}
              onChange={(e) => {
                setUserCode(e.target.value);
              }}
            />
            <button
              className="btn-solid"
              style={{ marginBottom: 20 }}
              onClick={() => {
                fetch(`${API_URL}users`)
                  .then((res) => res.json())
                  .then((json) => {
                    const found = json.find(
                      (realtor: RealtorType) =>
                        realtor.email.trim().toLowerCase() === email.trim().toLowerCase() &&
                        realtor.realtor?.userCode?.trim() === userCode.trim()
                    );
                    if (found) {
                      localStorage.setItem(
                        "username",
                        `${found.firstName} ${found.lastName}`
                      );
                    } else {
                      localStorage.removeItem("username");
                    }
                    const timer = setTimeout(() => {
                      window.location.href = `/${language}/dashboard`;
                    }, 1000);
                    return () => clearTimeout(timer);
                  });
              }}
            >
              {translate("LOGIN", language)}
            </button>
          </div>
        </div>
      </>
    </Layout>
  );
}
