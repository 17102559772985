import React from "react";
import Layout from "../components/Layout";
import { useParams } from "react-router";
import { RealtorType } from "../types";
import { translate } from "../languages";
import { API_URL, S3_URL } from "../consts";

export default function Realtor() {
  const { id, language } = useParams();
  const [realtor, setRealtor] = React.useState<RealtorType | null>();
  const [realtors, setRealtors] = React.useState<RealtorType[]>([]);
  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [subject, setSubject] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [balcklist, setBlacklist] = React.useState<string[]>([]);

  React.useEffect(() => {
    fetch(`${S3_URL}blacklist/blacklist.json?timestamp=${new Date().getTime()}`)
      .then((res) => res.json())
      .then((json) => {
        setBlacklist(json);
      });
  }, []);

  React.useEffect(() => {
    fetch(`${API_URL}users`)
      .then((res) => res.json())
      .then((json) => {
        setRealtors(json);
        json.forEach((r: RealtorType) => {
          if (r.id === Number(id)) {
            setRealtor(r);
          } else if (r.teamMembers) {
            r.teamMembers.forEach((m) => {
              if (m.id === Number(id)) {
                setRealtor(m);
              }
            });
          }
        });
      });
  }, [id]);

  React.useEffect(() => {
    fetch(`${S3_URL}branches/branches.json?timestamp=${new Date().getTime()}`)
      .then((res) => res.json())
      .then((json) => {
        json.forEach((r: RealtorType) => {
          if (r.id === Number(id)) {
            setRealtor(r);
          } else if (r.teamMembers) {
            r.teamMembers.forEach((m) => {
              if (m.id === Number(id)) {
                setRealtor(m);
              }
            });
          }
        });
      });
  }, [id]);

  return (
    <Layout>
      <>
        {realtor && (
          <>
            <div
              className="banner"
              style={{ backgroundImage: `url(${S3_URL}banner.jpg)` }}
            >
              <span className="text-uppercase">{`${realtor.firstName} ${realtor.lastName}`}</span>
              {translate(
                " - NU STREAM REALTY (TORONTO) INC., BROKERAGE",
                language
              )}
            </div>
            <div className="row">
              <div className="col col-12 col-lg-8">
                <div className="paper p-3 gap-10">
                  <div className="row w-100">
                    <div className="col col-12 col-lg-4 d-flex flex-column gap-10">
                      <img
                        src={realtor.photo}
                        style={{
                          width: "100%",
                          maxWidth: "400px",
                        }}
                        alt=""
                      ></img>
                      <div>{translate("CONTACT ME", language)}</div>
                      <input
                        className="form-control"
                        placeholder={translate("Your Name", language)}
                        value={name}
                        onChange={(e) => {
                          setName(e.target.value);
                        }}
                      />
                      <input
                        className="form-control"
                        placeholder={translate("Your Email Address", language)}
                        value={email}
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                      />
                      <input
                        className="form-control"
                        placeholder={translate("Your Phone", language)}
                        value={phone}
                        onChange={(e) => {
                          setPhone(e.target.value);
                        }}
                      />
                      <input
                        className="form-control"
                        placeholder={translate("Subject", language)}
                        value={subject}
                        onChange={(e) => {
                          setSubject(e.target.value);
                        }}
                      />
                      <textarea
                        className="form-control"
                        placeholder={translate("Message", language)}
                        style={{ height: 135 }}
                        onChange={(e) => {
                          setMessage(e.target.value);
                        }}
                      />
                      <button
                        className="btn-solid"
                        style={{ marginBottom: 20 }}
                        disabled={
                          !name || !email || !phone || !message || !subject
                        }
                        onClick={() => {
                          if (
                            !balcklist.includes(email) &&
                            !balcklist.includes(phone) &&
                            !balcklist.includes(name)
                          ) {
                            const to = realtor.email;
                            const from = "hr@nustreamtoronto.com";
                            const cc = "admin@nustreamtoronto.com";
                            const text = `Name: ${name}\n Email: ${email}\n Phone:${phone}\n Message:${message}`;
                            const html = `<div>Name: ${name}</div><div>Email: ${email}</div><div>Phone: ${phone}</div><div>Message: ${message}</div>`;
                            fetch(`${API_URL}mail`, {
                              method: "POST",
                              headers: {
                                "Content-Type": "application/json",
                                userName:
                                  localStorage.getItem("username") || "",
                              },
                              body: JSON.stringify({
                                from,
                                to,
                                cc,
                                subject,
                                text,
                                html,
                              }),
                            })
                              .then((res) => res.json())
                              .then((json) => {
                                if (!json.error) {
                                  alert(`${translate("Email sent", language)}`);
                                } else {
                                  console.error(json.error);
                                }
                              });
                          }
                        }}
                      >
                        {translate("SEND MESSAGE", language)}
                      </button>
                    </div>
                    <div className="col col-12 col-lg-8 d-flex flex-column gap-10">
                      <div className="text-uppercase fs-5">{`${realtor.firstName} ${realtor.lastName}`}</div>
                      <div className="text-ns">
                        {realtor.realtor?.licensedAs || (
                          <span>
                            &nbsp;
                            {localStorage.getItem("username") === "Admin" &&
                              realtor.realtor?.id && (
                                <input
                                  className="form-control"
                                  onBlur={(e) => {
                                    if (
                                      e.target.value &&
                                      e.target.value.length > 5
                                    ) {
                                      fetch(`${API_URL}users`, {
                                        method: "POST",
                                        headers: {
                                          "Content-Type": "application/json",
                                        },
                                        body: JSON.stringify({
                                          realtor: {
                                            id: realtor.realtor?.id,
                                            licensedAs: e.target.value,
                                          },
                                        }),
                                      })
                                        .then((res) => res.json())
                                        .then((json) => {
                                          if (json.error) {
                                            alert(json.error);
                                          } else {
                                            window.location.reload();
                                          }
                                        })
                                        .catch((ex) => {
                                          alert(ex);
                                        });
                                    }
                                  }}
                                ></input>
                              )}
                          </span>
                        )}
                      </div>
                      <div className="text-ns">
                        {realtor.teamLeaderId === realtor.id &&
                          realtor.realtor?.teamName}
                        {realtor.teamLeaderId !== realtor.id &&
                          realtors.find((r) => r.id === realtor.teamLeaderId)
                            ?.realtor?.teamName}
                      </div>
                      {realtor.teamMembers &&
                        realtor.teamMembers.length > 0 && (
                          <>
                            <hr />
                            <div>
                              <a href={`mailto:${realtor.email}`}>
                                <i className="fa fa-envelope text-ns"></i>
                              </a>{" "}
                              {translate("Email", language)}:{" "}
                              <span className="text-black-50 fs-14">
                                {realtor.email}
                              </span>
                            </div>
                            <div>
                              <a href={`tel:${realtor.phone}`}>
                                <i className="fa fa-phone text-ns"></i>
                              </a>{" "}
                              {translate("Phone", language)}:{" "}
                              <span className="text-black-50 fs-14">
                                {realtor.phone}
                              </span>
                            </div>
                          </>
                        )}
                      <hr />
                      <div>
                        <i className="fa fa-building text-ns"></i>{" "}
                        {translate("Company", language)}:
                        <span className="text-black-50 fs-14">
                          {" "}
                          {translate("NU STREAM", language)}
                        </span>
                      </div>
                      <hr />
                    </div>
                  </div>
                </div>
                {realtor.teamMembers && realtor.teamMembers.length > 0 && (
                  <>
                    <div className="paper">
                      <div className="tag">
                        {translate("TEAM MEMBERS", language)}
                      </div>
                    </div>
                    <div className="row">
                      {realtor.teamMembers
                        .filter(
                          (member) =>
                            member.photo &&
                            member.id !== realtor.id &&
                            member.status !== "DISABLED"
                        )
                        .map((member) => (
                          <div
                            key={`member-${member.id}`}
                            className="col col-12 col-lg-4"
                          >
                            <div className="paper p-3">
                              <div className="d-flex flex-column justify-content-center w-100">
                                <a href={`/${language}/realtor/${member.id}/`}>
                                  <div
                                    className="photo"
                                    style={{
                                      backgroundImage: `url(${encodeURI(
                                        member.photo
                                      )})`,
                                      height: 250,
                                    }}
                                  ></div>
                                </a>
                                <div className="text-center text-uppercase">{`${member.firstName} ${member.lastName}`}</div>
                                <div className="text-center text-ns">
                                  {member.realtor?.licensedAs || (
                                    <span>&nbsp;</span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                    </div>
                  </>
                )}
              </div>
              <div className="col col-12 col-lg-4">
                <div className="paper d-flex flex-column p-3">
                  <h5>{translate("NU STREAM AGENTS", language)}</h5>
                  <hr />
                  <p className="text-black-50 fs-14">
                    {translate(
                      "Contact our professional Real Estate Agents for a free property consultation.",
                      language
                    )}
                  </p>
                  {realtors &&
                    realtors
                      .filter((r) => r.photo && r.status !== "DISABLED")
                      .slice(-3)
                      .map((r) => {
                        return (
                          <a
                            key={`new-realtor-${r.id}`}
                            href={`/${language}/realtor/${r.id}/`}
                            className="link"
                          >
                            <img
                              src={r.photo}
                              style={{
                                width: "100px",
                                margin: "10px",
                              }}
                              alt=""
                            ></img>
                            <span className="text-uppercase fs-5 p-3">
                              {`${r.firstName} ${r.lastName}`}
                            </span>
                            <hr />
                          </a>
                        );
                      })}
                </div>
              </div>
            </div>
          </>
        )}
      </>
    </Layout>
  );
}
