import React from "react";
import { useParams } from "react-router-dom";
import Layout from "../components/Layout";
import { API_URL, S3_URL } from "../consts";
import { translate } from "../languages";
import Files from "../components/Files";

export default function Admin() {
  const { language } = useParams();
  const [token, setToken] = React.useState("");

  return (
    <Layout>
      <>
        <div
          className="banner"
          style={{ backgroundImage: `url(${S3_URL}banner.jpg)` }}
        >
          {translate(
            "ADMIN - NU STREAM REALTY (TORONTO) INC., BROKERAGE",
            language
          )}
        </div>
        <div className="row w-100">
          <div className="col col-12">
            <hr />
          </div>
          <div className="col col-12 col-lg-8">
            <input
              className="form-control"
              placeholder={translate(
                "Please enter the Admin Token here!",
                language
              )}
              value={token}
              onChange={(e) => {
                setToken(e.target.value);
              }}
            />
          </div>
          <div className="col col-12 col-lg-4 d-flex flex-column gap-10">
            <button
              className="btn-solid"
              onClick={() => {
                fetch(`${API_URL}token`)
                  .then((res) => res.json())
                  .then((json) => {
                    if (json.error) {
                      console.error(json.error);
                      alert("Can't send token, please try later!");
                    } else {
                      alert(json.message);
                    }
                  })
                  .catch((ex) => {
                    console.error(ex);
                    alert("Can't send token, please try later!");
                  });
              }}
            >
              {translate("Send Token", language)}
            </button>
          </div>
          {token && (
            <>
              <div className="col col-12">
                <hr />
              </div>
              <div className="col col-12 col-lg-6 d-flex flex-column gap-10">
                <button
                  className="btn-solid"
                  onClick={() => {
                    fetch(`${API_URL}cache`, {
                      method: "GET",
                      headers: { "Content-Type": "application/json", token },
                    })
                      .then((res) => res.json())
                      .then((json) => {
                        if (json.error) {
                          alert(json.error);
                        } else {
                          alert(json.message);
                        }
                      })
                      .catch((ex) => {
                        alert(ex);
                      });
                  }}
                >
                  {translate("Refresh Cache", language)}
                </button>
              </div>
              <div className="col col-12 col-lg-6 d-flex flex-column gap-10">
                <button
                  className="btn-solid"
                  onClick={() => {
                    fetch(`${API_URL}token`, {
                      method: "POST",
                      headers: { "Content-Type": "application/json", token },
                    })
                      .then((res) => res.json())
                      .then((json) => {
                        if (json.error) {
                          alert(json.error);
                        } else {
                          localStorage.setItem("username", "Admin");
                          const timer = setTimeout(() => {
                            window.location.href = `/${language}/dashboard`;
                          }, 1000);
                          return () => clearTimeout(timer);
                        }
                      })
                      .catch((ex) => {
                        alert(ex);
                      });
                  }}
                >
                  {translate("Login as ADMIN", language)}
                </button>
              </div>
              <div className="col col-12">
                <hr />
              </div>
              <div className="col col-12">
                {translate("Website Settings", language)}
              </div>
              <div className="col col-12 d-flex flex-column gap-10">
                <ul className="nav nav-tabs" id="myTab" role="tablist">
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link active"
                      id="files-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#files-tab-pane"
                      type="button"
                      role="tab"
                      aria-controls="files-tab-pane"
                      aria-selected="true"
                    >
                      {translate("Files", language)}
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="branches-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#branches-tab-pane"
                      type="button"
                      role="tab"
                      aria-controls="branches-tab-pane"
                      aria-selected="false"
                    >
                      {translate("Branches", language)}
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="bannersen-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#bannersen-tab-pane"
                      type="button"
                      role="tab"
                      aria-controls="bannersen-tab-pane"
                      aria-selected="false"
                    >
                      {translate("English Banners", language)}
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="bannerscn-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#bannerscn-tab-pane"
                      type="button"
                      role="tab"
                      aria-controls="bannerscn-tab-pane"
                      aria-selected="false"
                    >
                      {translate("Chinese Banners", language)}
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="teams-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#teams-tab-pane"
                      type="button"
                      role="tab"
                      aria-controls="teams-tab-pane"
                      aria-selected="false"
                    >
                      {translate("Teams", language)}
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="blacklist-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#blacklist-tab-pane"
                      type="button"
                      role="tab"
                      aria-controls="blacklist-tab-pane"
                      aria-selected="false"
                    >
                      {translate("Blacklist", language)}
                    </button>
                  </li>
                </ul>
                <div className="tab-content">
                  <div
                    className="tab-pane fade show active"
                    id="files-tab-pane"
                    role="tabpanel"
                    aria-labelledby="files-tab"
                    tabIndex={0}
                  >
                    <Files folder="files" token={token} />
                  </div>
                  <div
                    className="tab-pane fade"
                    id="branches-tab-pane"
                    role="tabpanel"
                    aria-labelledby="branches-tab"
                    tabIndex={1}
                  >
                    <Files folder="branches" token={token} />
                  </div>
                  <div
                    className="tab-pane fade"
                    id="bannersen-tab-pane"
                    role="tabpanel"
                    aria-labelledby="bannersen-tab"
                    tabIndex={2}
                  >
                    <Files folder="bannersen" token={token} />
                  </div>
                  <div
                    className="tab-pane fade"
                    id="bannerscn-tab-pane"
                    role="tabpanel"
                    aria-labelledby="bannerscn-tab"
                    tabIndex={3}
                  >
                    <Files folder="bannerscn" token={token} />
                  </div>
                  <div
                    className="tab-pane fade"
                    id="teams-tab-pane"
                    role="tabpanel"
                    aria-labelledby="teams-tab"
                    tabIndex={4}
                  >
                    <Files folder="teams" token={token} />
                  </div>
                  <div
                    className="tab-pane fade"
                    id="blacklist-tab-pane"
                    role="tabpanel"
                    aria-labelledby="blacklist-tab"
                    tabIndex={4}
                  >
                    <Files folder="blacklist" token={token} />
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </>
    </Layout>
  );
}
