import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import "./App.css";
import Home from "./pages/Home";
import About from "./pages/About";
import Realtor from "./pages/Realtor";
import Team from "./pages/Team";
import Contact from "./pages/Contact";
import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import Forms from "./pages/Forms";
import Admin from "./pages/Admin";

export default function App() {
  return (
    <Router>
      <Routes>
        <Route path="/:language/" element={<Home />} />
        <Route path="/:language/about/" element={<About />} />
        <Route path="/:language/realtor/:id/" element={<Realtor />} />
        <Route path="/:language/team/" element={<Team />} />
        <Route path="/:language/contact/" element={<Contact />} />
        <Route path="/:language/login/" element={<Login />} />
        <Route path="/:language/dashboard/" element={<Dashboard />} />
        <Route path="/:language/forms/:formName" element={<Forms />} />
        <Route path="/:language/admin/" element={<Admin />} />
        <Route path="*" element={<Navigate to="/en/" />} />
      </Routes>
    </Router>
  );
}
