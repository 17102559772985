import React from "react";
import { useParams } from "react-router-dom";
import Layout from "../components/Layout";
import { API_URL, S3_URL } from "../consts";
import { translate } from "../languages";
import { RealtorType } from "../types";

export default function About() {
  const { language } = useParams();
  const [rate, setRate] = React.useState(0);
  const [teams, setTeams] = React.useState(0);

  React.useEffect(() => {
    if (rate < 1) {
      const timer = setTimeout(() => {
        setRate(rate + 0.01);
      }, 1);
      return () => clearTimeout(timer);
    }
  }, [rate]);

  React.useEffect(() => {
    fetch(`${API_URL}users`)
      .then((res) => res.json())
      .then((json) => {
        setTeams(
          json.filter(
            (r: RealtorType) =>
              r.realtor && r.photo && r.teamMembers && r.teamMembers.length > 0
          ).length
        );
      });
  }, []);

  return (
    <Layout>
      <>
        <div
          className="banner"
          style={{ backgroundImage: `url(${S3_URL}banner.jpg)` }}
        >
          {translate(
            "ABOUT US - NU STREAM REALTY (TORONTO) INC., BROKERAGE",
            language
          )}
        </div>
        <div className="paper">
          <div className="tag">{translate("ABOUT NU STREAM", language)}</div>
        </div>
        <div className="paper">
          <div className="row w-100">
            <div className="col col-12 col-lg-6 p-3">
              <iframe
                title="youtube"
                src="https://player.vimeo.com/video/775298303?h=63082103a6&dnt=1&app_id=122963"
                style={{ width: "100%", height: 360 }}
              ></iframe>
            </div>
            <div className="col col-12 col-lg-6 p-3">
              <div className="paper">
                <div className="tag">
                  {translate("COMPANY BACKGROUND", language)}
                </div>
              </div>
              <p className="fs-14 text-black-50 fw-bold">
                {translate(
                  "Nu Stream Realty was founded December 2015 in Vancouver (Nu Stream Realty [Toronto] Inc. Brokerage licensed August 2016 in Ontario). It came with a strong investment and numerous resources which associated to the Lenovo Group. Our powerful background is giving us such support for Nu Stream Realty to do something completely different compared to traditional real estate brokerage. As a pioneer of the new model of real estate brokerage, Nu Stream Realty has such a great start.",
                  language
                )}
              </p>
              <p className="fs-14 text-black-50 fw-bold">
                {translate(
                  "At the end of December 2017, Nu Stream Realty has over 350 local Real Estate Agents in Vancouver and Toronto with over 500 active listings, and the number is increasing continually. We had more than 3407 deals completed less than three years. Our sales turnover is more than 2.23 billion Canadian Dollars during 2017. Even with this milestone, Nu Stream Realty continues to plan ahead for new achievements. We have established itself upon bridging the Real Estate Services between China and Canada which has further expanded to the overseas market.",
                  language
                )}
              </p>
            </div>
          </div>
        </div>
        <div className="paper">
          <div className="tag">{translate("OUR STRENGTH", language)}</div>
        </div>
        <div className="paper">
          <div className="row w-100">
            <div className="col col-12 col-lg-6 fs-14 fw-bold p-3">
              {translate(
                "We can get your home sold the fastest and at the highest price in the shortest time on the market, and the sales image of the property can also reach the top level in the market. The tenet of THE WHOLE COMPANY IS AT YOUR SERVICE allows you to feel at ease, rest assured, and worry-free whether you are selling or buying a house.",
                language
              )}
            </div>
            <div className="col col-12 col-lg-6 p-3">
              <div className="progress" style={{ height: 30, margin: 10 }}>
                <div
                  className="progress-bar bg-ns"
                  style={{
                    width: `${rate * 90}%`,
                    textAlign: "left",
                    paddingLeft: 10,
                  }}
                >
                  {translate("Reliability ", language)}
                  90%
                </div>
              </div>
              <div className="progress" style={{ height: 30, margin: 10 }}>
                <div
                  className="progress-bar bg-ns"
                  style={{
                    width: `${rate * 99}%`,
                    textAlign: "left",
                    paddingLeft: 10,
                  }}
                >
                  {translate("Professionalism ", language)}
                  99%
                </div>
              </div>
              <div className="progress" style={{ height: 30, margin: 10 }}>
                <div
                  className="progress-bar bg-ns"
                  style={{
                    width: `${rate * 99}%`,
                    textAlign: "left",
                    paddingLeft: 10,
                  }}
                >
                  {translate("Visibility ", language)}
                  99%
                </div>
              </div>
              <div className="progress" style={{ height: 30, margin: 10 }}>
                <div
                  className="progress-bar bg-ns"
                  style={{
                    width: `${rate * 90}%`,
                    textAlign: "left",
                    paddingLeft: 10,
                  }}
                >
                  {translate("Responsiveness ", language)}
                  90%
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="paper">
          <div className="row w-100">
            <div className="col col-12 col-lg-6 p-3">
              <div className="paper">
                <div className="tag">{translate("SERVICE AREA", language)}</div>
              </div>
              <p className="fs-14 text-black-50 fw-bold">
                {translate(
                  "Nu Stream Realty Toronto focuses on real estate investment, commercial real estate, as well as, strategic marketing planning, investment consulting and real estate licensing training.",
                  language
                )}
              </p>
              <p className="fs-14 text-black-50 fw-bold">
                {translate(
                  "The company has successfully established ",
                  language
                )}
                {teams}
                {translate(
                  " teams, providing services in Toronto GTA and surrounding areas. Additionally, our Commercial Department offers a full range of Sales & leasing services for your property.  Also, Nu Stream Commercial provides project marketing services for all of the real estate sectors including industrial, office, retail and residential.",
                  language
                )}
              </p>
            </div>
            <div className="col col-12 col-lg-6 p-3">
              <img
                src={`${S3_URL}area.png`}
                style={{ width: "100%" }}
                alt=""
              ></img>
            </div>
          </div>
        </div>
        <div className="paper d-flex flex-column">
          <div className="paper">
            <div className="tag">
              {translate(
                "COMPANY POSITIONING AND UNIQUE MANAGEMENT FEATURES",
                language
              )}
            </div>
          </div>
          <p className="fs-14 text-black-50 fw-bold">
            {translate(
              "Providing real estate services to the global Chinese market.",
              language
            )}
          </p>
          <p className="fs-14 text-black-50 fw-bold">
            {translate(
              "Strategic team work collaboration, usage of practical social media platforms, and business Partnership with Lenovo Group China, providing clients with professional services to ensure efficiency and satisfaction for each transaction. Also allowing Realtors to be more professional more focused, to better serve their clients, and to grow rapidly along with outstanding performances and result.",
              language
            )}
          </p>
          <p className="fs-14 text-black-50 fw-bold">
            {translate(
              "We strive for standardized management, healthy upward culture, immense goals, and highest customer satisfaction possible.",
              language
            )}
          </p>
        </div>
        <div className="paper">
          <div className="row w-100">
            <div className="col col-12 col-lg-6 p-3">
              <img
                src={`${S3_URL}cooperation.jpg`}
                style={{ width: "100%" }}
                alt=""
              ></img>
            </div>
            <div className="col col-12 col-lg-6 p-3">
              <div className="paper">
                <div className="tag">
                  {translate("STRATEGIC COOPERATION", language)}
                </div>
              </div>
              <p className="fs-14 text-black-50 fw-bold">
                {translate(
                  "In addition to its continuous growth, Nu Stream Realty places great importance on strategic cooperation with its business partners. At present, Nu Stream Realty is collaborating with numerous real estate social media platforms that hold a large number of investors, providing first-hand information on the local properties to the potential buyers; minimizing the selling period and maximizing the property exposure.",
                  language
                )}
              </p>
            </div>
          </div>
        </div>
      </>
    </Layout>
  );
}
