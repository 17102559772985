import React from "react";
import { useParams } from "react-router-dom";
import Layout from "../components/Layout";
import Teams from "../components/Teams";
import { S3_URL } from "../consts";
import { translate } from "../languages";

export default function Team() {
  const { language } = useParams();

  return (
    <Layout>
      <>
        <div
          className="banner"
          style={{ backgroundImage: `url(${S3_URL}banner.jpg)` }}
        >
          {translate(
            "OUR TEAM - NU STREAM REALTY (TORONTO) INC., BROKERAGE",
            language
          )}
        </div>
        <Teams />
      </>
    </Layout>
  );
}
