import React from "react";
import { useLocation, useParams } from "react-router-dom";
import { translate } from "../languages";
import { S3_URL } from "../consts";

interface PropsInterface {
  children: JSX.Element;
}

export default function Layout({ children }: PropsInterface) {
  const { language } = useParams();
  if (!["cn", "en"].includes(`${language}`)) {
    window.location.href = "/en/";
  }
  const [id, setId] = React.useState("");
  const [showMenu, setShowMenu] = React.useState(false);
  const username = localStorage.getItem("username");
  const location = useLocation();
  const menu = (
    <>
      <li className="nav-item">
        <a
          href={`/${language}/`}
          className={`fw-bold fs-14 nav-link ${
            location.pathname === `/${language}/` ? "active" : ""
          }`}
        >
          {translate("HOME", language)}
        </a>
      </li>
      <li className="nav-item">
        <a
          href={`/${language}/about/`}
          className={`fw-bold fs-14 nav-link ${
            location.pathname === `/${language}/about/` ? "active" : ""
          }`}
        >
          {translate("ABOUT US", language)}
        </a>
      </li>
      <li className="nav-item">
        <a
          href={`/${language}/team/`}
          className={`fw-bold fs-14 nav-link ${
            location.pathname === `/${language}/team/` ? "active" : ""
          }`}
        >
          {translate("OUR TEAM", language)}
        </a>
      </li>
      <li className="nav-item">
        <a href="https://tor.johome.com/" className={`fw-bold fs-14 nav-link`}>
          {translate("LISTING INFO", language)}
        </a>
      </li>
      <li className="nav-item">
        <a
          href={`/${language}/contact/`}
          className={`fw-bold fs-14 nav-link ${
            location.pathname === `/${language}/contact/` ? "active" : ""
          }`}
        >
          {translate("CONTACT US", language)}
        </a>
      </li>
    </>
  );

  return (
    <div>
      <div className="container-top w-100">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-lg-8 justify-content-center justify-content-lg-start gap-10">
              <a href="https://www.facebook.com/nustreamtoronto/">
                <i className="fa-brands fa-facebook text-light"></i>
              </a>
              <a href="https://www.instagram.com/nustreamtoronto/">
                <i className="fa-brands fa-instagram text-light"></i>
              </a>
              <a href="https://www.linkedin.com/company/nu-stream-toronto-inc-brokerage/">
                <i className="fa-brands fa-linkedin text-light"></i>
              </a>
              <a href="https://www.youtube.com/@nustreamtoronto7942">
                <i className="fa-brands fa-youtube text-light"></i>
              </a>
              <a
                href="mailto:salesto@nustreamrealty.com?subject=Website%20Enquiry"
                className="text-light"
              >
                <i className="fa fa-envelope"></i>
                <span>&nbsp;</span>
                <span className="d-none d-lg-inline d-xl-inline d-xxl-inline">
                  salesto@nustreamrealty.com
                </span>
              </a>
              <a href="tel:416-333-1111" className="text-light">
                <i className="fa fa-phone"></i>
                <span>&nbsp;</span>
                <span className="d-none d-lg-inline d-xl-inline d-xxl-inline">
                  416-333-1111
                </span>
              </a>
            </div>
            <div className="col-md-12 col-lg-4 justify-content-center justify-content-lg-end gap-10">
              <div
                className="link text-light"
                onClick={() => {
                  window.location.href = window.location.href.replace(
                    "/cn/",
                    "/en/"
                  );
                }}
              >
                English
              </div>
              <div
                className="link text-light"
                onClick={() => {
                  window.location.href = window.location.href.replace(
                    "/en/",
                    "/cn/"
                  );
                }}
              >
                中文
              </div>
              <a
                href={
                  username ? `/${language}/dashboard/` : `/${language}/login/`
                }
                className="text-warning"
              >
                <i className="fa fa-user"></i>
                <span> {username ? `Hello, ${username}` : "Agents Login"}</span>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="container-middle">
        <div className="container">
          <img src={`${S3_URL}logo.png`} style={{ width: "250px" }} alt="" />
          <div className="d-none d-lg-inline">
            <ul className="nav justify-content-end">{menu}</ul>
          </div>
          <div className="d-lg-none d-flex justify-content-end w-100">
            <button
              className="btn-solid"
              onClick={() => {
                setShowMenu(!showMenu);
              }}
            >
              <i className="fa fa-bars"></i>
            </button>
          </div>
        </div>
        <div className="container" style={{ position: "relative" }}>
          {showMenu && (
            <ul
              className="d-lg-none nav d-flex flex-column"
              style={{ position: "absolute", top: 0, right: 12, zIndex: 1 }}
            >
              {menu}
            </ul>
          )}
          {children}
        </div>
      </div>
      <div
        className="container-bottom"
        style={{ backgroundImage: `url(${S3_URL}footer.png)` }}
      >
        <div className="row gap-20">
          <div className="col-md-12 col-lg-4 d-flex justify-content-center gap-10">
            <a href="https://www.facebook.com/nustreamtoronto/">
              <i
                className="fa-brands fa-facebook text-primary"
                style={{ fontSize: "150%" }}
              ></i>
            </a>
            <a href="https://www.instagram.com/nustreamtoronto/">
              <i
                className="fa-brands fa-instagram text-danger"
                style={{ fontSize: "150%" }}
              ></i>
            </a>
            <a href="https://www.linkedin.com/company/nu-stream-toronto-inc-brokerage/">
              <i
                className="fa-brands fa-linkedin text-info"
                style={{ fontSize: "150%" }}
              ></i>
            </a>
            <a href="https://www.youtube.com/@nustreamtoronto7942">
              <i
                className="fa-brands fa-youtube text-danger"
                style={{ fontSize: "150%" }}
              ></i>
            </a>
          </div>
          <div className="col-md-12 col-lg-4 d-flex justify-content-center">
            <div>
              <input
                className="input-search"
                placeholder={translate("Enter Listing ID", language)}
                value={id}
                onChange={(e) => {
                  setId(e.target.value);
                }}
              />
              <button
                className="btn-solid"
                onClick={() => {
                  if (id) {
                    window.open(`https://tor.johome.com/house?id=TR${id}`);
                  }
                }}
              >
                {translate("Go", language)}
              </button>
            </div>
          </div>
          <div className="col-md-12 col-lg-4 d-flex justify-content-center">
            <div>
              <div>
                <h4>{translate("Contact With Us", language)}</h4>
              </div>
              <div>
                <a href="tel:416-333-1111" className="text-black-50">
                  <i className="fa fa-phone"></i>
                  <span>&nbsp;</span>
                  <span>416-333-1111</span>
                </a>
              </div>
              <div>
                <a
                  href="mailto:salesto@nustreamrealty.com?subject=Website%20Enquiry"
                  className="text-black-50"
                >
                  <i className="fa fa-envelope"></i>
                  <span>&nbsp;</span>
                  <span>salesto@nustreamrealty.com</span>
                </a>
              </div>
              <div className="text-black-50">
                <i className="fa fa-map-marker"></i>
                <span>&nbsp;</span>
                <span>140 York Blvd, Richmond Hill, L4B 3J6</span>
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-center text-black-50">
          {translate(
            "© Copyright 2016. All Rights Reserved by NU STREAM REALTY (TORONTO) INC., BROKERAGE",
            language
          )}
        </div>
      </div>
    </div>
  );
}
