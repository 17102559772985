import React from "react";
import { useParams } from "react-router-dom";
import { translate } from "../languages";
import { API_URL, DESIGNER_EMAIL, S3_URL } from "../consts";

export default function BusinessCardOrderForm() {
  const { language } = useParams();
  const [form, setForm] = React.useState<{ [key: string]: any }>({
    SUBJECT: "Business Card Order Form",
  });
  const [status, setStatus] = React.useState("");
  const [teams, setTeams] = React.useState<{ order: number; name: string }[]>(
    []
  );

  React.useEffect(() => {
    fetch(`${S3_URL}teams/teams.json?timestamp=${new Date().getTime()}`)
      .then((res) => res.json())
      .then((json) => {
        setTeams(json);
      });
  }, []);

  React.useEffect(() => {
    if (
      form["TRADE NAME"] &&
      form["TEAM NAME"] &&
      form["TITLE"] &&
      form["CELL PHONE"] &&
      form["EMAIL ADDRESS"] &&
      form["BUSINESS CARD WITH QR CODE"] &&
      form["BUSINESS CARD WITH HD PHOTO"] &&
      form["QUANTITY"] &&
      form["EMAIL ADDRESS"] === form["CONFIRM EMAIL ADDRESS"] &&
      (form["BUSINESS CARD WITH QR CODE"] === "No" || form["JOHOME QR CODE"])
    ) {
      setStatus("sendable");
    } else {
      setStatus("");
    }
  }, [form]);

  return (
    <>
      <div className="paper">
        <div className="tag text-capitalize">
          {translate("Business Card Order Form", language)}
        </div>
      </div>
      <div className="paper d-flex flex-column gap-10">
        <div className="row w-100">
          <div className="col col-12 col-lg-6 p-3">
            <label className="form-label">
              {translate("TRADE NAME*", language)}
            </label>
            <input
              className="form-control"
              value={form["TRADE NAME"] || ""}
              onChange={(e) => {
                setForm({ ...form, "TRADE NAME": e.target.value });
              }}
            />
          </div>
          <div className="col col-12 col-lg-6 p-3">
            <label className="form-label">
              {translate("FOREIGN NAME", language)}
            </label>
            <input
              className="form-control"
              value={form["FOREIGN NAME"] || ""}
              onChange={(e) => {
                setForm({ ...form, "FOREIGN NAME": e.target.value });
              }}
            />
          </div>
          <div className="col col-12 col-lg-6 p-3">
            <label className="form-label">
              {translate("TEAM NAME*", language)}
            </label>
            <select
              className="form-select"
              value={form["TEAM NAME"] || ""}
              onChange={(e) => {
                setForm({ ...form, "TEAM NAME": e.target.value });
              }}
            >
              <option value="" disabled></option>
              {teams
                .sort((a, b) => a.order - b.order)
                .map(({ name }) => (
                  <option value={name}>{name}</option>
                ))}
            </select>
          </div>
          <div className="col col-12 col-lg-6 p-3">
            <label className="form-label">
              {translate("TITLE*", language)}
            </label>
            <div className="row w-100">
              <div className="col col-12 col-lg-6 p-3">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="title"
                    value="Sales Rep"
                    checked={form["TITLE"] === "Sales Rep"}
                    onChange={(e) => {
                      setForm({ ...form, TITLE: e.target.value });
                    }}
                  />
                  <label className="form-check-label">
                    {translate("Sales Rep", language)}
                  </label>
                </div>
              </div>
              <div className="col col-12 col-lg-6 p-3">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="title"
                    value="Broker"
                    checked={form["TITLE"] === "Broker"}
                    onChange={(e) => {
                      setForm({ ...form, TITLE: e.target.value });
                    }}
                  />
                  <label className="form-check-label">
                    {translate("Broker", language)}
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="col col-12 col-lg-6 p-3">
            <label className="form-label">
              {translate("CELL PHONE*", language)}
            </label>
            <input
              className="form-control"
              value={form["CELL PHONE"] || ""}
              onChange={(e) => {
                setForm({ ...form, "CELL PHONE": e.target.value });
              }}
            />
          </div>
          <div className="col col-12 col-lg-6 p-3">
            <label className="form-label">
              {translate("EMAIL ADDRESS*", language)}
            </label>
            <div className="row w-100">
              <div className="col col-12 col-lg-6">
                <input
                  className="form-control"
                  value={form["EMAIL ADDRESS"] || ""}
                  placeholder="Email"
                  onChange={(e) => {
                    setForm({ ...form, "EMAIL ADDRESS": e.target.value });
                  }}
                />
              </div>
              <div className="col col-12 col-lg-6">
                <input
                  className="form-control"
                  value={form["CONFIRM EMAIL ADDRESS"] || ""}
                  placeholder="Confirm Email"
                  onChange={(e) => {
                    setForm({
                      ...form,
                      "CONFIRM EMAIL ADDRESS": e.target.value,
                    });
                  }}
                />
              </div>
            </div>
          </div>
          <div className="col col-12 col-lg-6 p-3">
            <label className="form-label">
              {translate("BUSINESS CARD WITH QR CODE*", language)}
            </label>
            <div className="row w-100">
              <div className="col col-12 col-lg-6 p-3">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    checked={form["BUSINESS CARD WITH QR CODE"] === "Yes"}
                    onChange={(e) => {
                      setForm({
                        ...form,
                        "BUSINESS CARD WITH QR CODE": "Yes",
                      });
                    }}
                  />
                  <label className="form-check-label">
                    {translate("Yes", language)}
                  </label>
                </div>
              </div>
              <div className="col col-12 col-lg-6 p-3">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    checked={form["BUSINESS CARD WITH QR CODE"] === "No"}
                    onChange={(e) => {
                      setForm({
                        ...form,
                        "BUSINESS CARD WITH QR CODE": "No",
                      });
                    }}
                  />
                  <label className="form-check-label">
                    {translate("No", language)}
                  </label>
                </div>
              </div>
            </div>
          </div>
          {form["BUSINESS CARD WITH QR CODE"] === "Yes" && (
            <div className="col col-12 p-3">
              <label className="form-label">
                {translate("UPLOAD YOUR WECHAT QR CODE*", language)}
              </label>
              <input
                className="form-control"
                type="file"
                accept="image/*"
                onChange={(e) => {
                  if (e.target.files?.length === 1) {
                    const fileReader = new FileReader();
                    fileReader.onloadend = (e) => {
                      if (e.target?.result) {
                        setForm({
                          ...form,
                          "WECHAT QR CODE": e.target.result as string,
                        });
                      }
                    };
                    fileReader.readAsDataURL(e.target.files[0]);
                  }
                }}
              />
              {form["WECHAT QR CODE"] && (
                <img
                  src={form["WECHAT QR CODE"]}
                  alt=""
                  style={{ maxWidth: 500 }}
                ></img>
              )}
            </div>
          )}
          {form["BUSINESS CARD WITH QR CODE"] === "Yes" && (
            <div className="col col-12 p-3">
              <label className="form-label">
                {translate("UPLOAD YOUR JOHOME QR CODE (REQUIRED)*", language)}
              </label>
              <input
                className="form-control"
                type="file"
                accept="image/*"
                onChange={(e) => {
                  if (e.target.files?.length === 1) {
                    const fileReader = new FileReader();
                    fileReader.onloadend = (e) => {
                      if (e.target?.result) {
                        setForm({
                          ...form,
                          "JOHOME QR CODE": e.target.result as string,
                        });
                      }
                    };
                    fileReader.readAsDataURL(e.target.files[0]);
                  }
                }}
              />
              <div className="text-black-50 fs-14">
                Your Johome invitation code. You must have a Johome invitation
                code to order your business card.
              </div>
              {form["JOHOME QR CODE"] && (
                <img
                  src={form["JOHOME QR CODE"]}
                  alt=""
                  style={{ maxWidth: 500 }}
                ></img>
              )}
            </div>
          )}
          <div className="col col-12">
            <div className="row">
              <div className="col col-12 col-lg-3 p-3 d-flex flex-column">
                <label className="form-label">
                  {translate("WeChat QR Code Sample", language)}
                </label>
                <img
                  src={`${S3_URL}qrcodeSample1.jpg`}
                  alt=""
                  style={{ width: 250 }}
                />
              </div>
              <div className="col col-12 col-lg-3 p-3 d-flex flex-column">
                <label className="form-label">
                  {translate("How to get JoHome Code", language)}
                </label>
                <img
                  src={`${S3_URL}qrcodeSample2.png`}
                  alt=""
                  style={{ width: 250 }}
                />
              </div>
              <div className="col col-12 col-lg-3 p-3 d-flex flex-column">
                <label className="form-label">
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </label>
                <img
                  src={`${S3_URL}qrcodeSample3.png`}
                  alt=""
                  style={{ width: 250 }}
                />
              </div>
              <div className="col col-12 col-lg-3 p-3 d-flex flex-column">
                <label className="form-label">
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </label>
                <img
                  src={`${S3_URL}qrcodeSample4.png`}
                  alt=""
                  style={{ width: 250 }}
                />
              </div>
            </div>
          </div>
          <div className="col col-12 col-lg-6 p-3">
            <label className="form-label">
              {translate("BUSINESS CARD WITH HD PHOTO*", language)}
            </label>
            <div className="row w-100">
              <div className="col col-12 col-lg-6 p-3">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    checked={form["BUSINESS CARD WITH HD PHOTO"] === "Yes"}
                    onChange={() => {
                      setForm({
                        ...form,
                        "BUSINESS CARD WITH HD PHOTO": "Yes",
                      });
                    }}
                  />
                  <label className="form-check-label">
                    {translate("Yes", language)}
                  </label>
                </div>
              </div>
              <div className="col col-12 col-lg-6 p-3">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    checked={form["BUSINESS CARD WITH HD PHOTO"] === "No"}
                    onChange={() => {
                      setForm({
                        ...form,
                        "BUSINESS CARD WITH HD PHOTO": "No",
                      });
                    }}
                  />
                  <label className="form-check-label">
                    {translate("No", language)}
                  </label>
                </div>
              </div>
            </div>
          </div>
          {form["BUSINESS CARD WITH HD PHOTO"] === "Yes" && (
            <div className="col col-12 p-3">
              <label className="form-label">
                {translate("UPLOAD YOUR HD PHOTO", language)}
              </label>
              <input
                className="form-control"
                type="file"
                accept="image/*"
                onChange={(e) => {
                  if (e.target.files?.length === 1) {
                    const fileReader = new FileReader();
                    fileReader.onloadend = (e) => {
                      if (e.target?.result) {
                        setForm({
                          ...form,
                          "HD PHOTO": e.target.result as string,
                        });
                      }
                    };
                    fileReader.readAsDataURL(e.target.files[0]);
                  }
                }}
              />
              {form["HD PHOTO"] && (
                <img
                  src={form["HD PHOTO"]}
                  alt=""
                  style={{ maxWidth: 500 }}
                ></img>
              )}
            </div>
          )}
          <div className="col col-12 p-3">
            <label className="form-label">
              {translate("QUANTITY*", language)}
            </label>
            <select
              className="form-select"
              value={form["QUANTITY"] || ""}
              onChange={(e) => {
                setForm({ ...form, QUANTITY: e.target.value });
              }}
            >
              <option value="" disabled></option>
              <option value="Digital Copy Only">Digital Copy Only</option>
              <option value="500">500</option>
              <option value="1,000">1,000</option>
              <option value="2,500">2,500</option>
              <option value="5,000">5,000</option>
            </select>
          </div>
          <div className="col col-12 p-3">
            <label className="form-label">
              {translate("SUB BROKERAGE LOGO", language)}
            </label>
            <input
              className="form-control"
              type="file"
              accept="image/*"
              onChange={(e) => {
                if (e.target.files?.length === 1) {
                  const fileReader = new FileReader();
                  fileReader.onloadend = (e) => {
                    if (e.target?.result) {
                      setForm({
                        ...form,
                        "SUB BROKERAGE LOGO": e.target.result as string,
                      });
                    }
                  };
                  fileReader.readAsDataURL(e.target.files[0]);
                }
              }}
            />
            {form["SUB BROKERAGE LOGO"] && (
              <img
                src={form["SUB BROKERAGE LOGO"]}
                alt=""
                style={{ maxWidth: 500 }}
              ></img>
            )}
          </div>
          <div className="col col-12 p-3">
            {status === "" && (
              <button
                className="btn-solid"
                style={{ backgroundColor: "#000000" }}
                disabled
              >
                Submit
              </button>
            )}
            {status === "sendable" && (
              <button
                className="btn-solid"
                onClick={() => {
                  setStatus("sending");
                  const to = DESIGNER_EMAIL;
                  const from = "hr@nustreamtoronto.com";
                  const cc = "admin@nustreamtoronto.com";
                  const subject = form["SUBJECT"];
                  const text = JSON.stringify(form);
                  let html =
                    '<table style="width:100%;border: 1px solid;border-collapse: collapse;">';

                  const formProperties = [
                    "SUBJECT",
                    "TRADE NAME",
                    "FOREIGN NAME",
                    "TEAM NAME",
                    "TITLE",
                    "CELL PHONE",
                    "EMAIL ADDRESS",
                    "CONFIRM EMAIL ADDRESS",
                    "BUSINESS CARD WITH QR CODE",
                    "WECHAT QR CODE",
                    "JOHOME QR CODE",
                    "BUSINESS CARD WITH HD PHOTO",
                    "HD PHOTO",
                    "QUANTITY",
                    "SUB BROKERAGE LOGO",
                  ];

                  for (const formProperty of formProperties) {
                    if (form[formProperty]) {
                      html =
                        html +
                        '<tr><td style="width:40%;border: 1px solid;border-collapse: collapse;">' +
                        formProperty +
                        '</td><td style="width:60%;border: 1px solid;border-collapse: collapse;">' +
                        form[formProperty] +
                        "</td></tr>";
                    }
                  }

                  html = html + "</table>";
                  fetch(`${API_URL}mail`, {
                    method: "POST",
                    headers: {
                      "Content-Type": "application/json",
                      userName: localStorage.getItem("username") || "",
                    },
                    body: JSON.stringify({ from, to, cc, subject, text, html }),
                  })
                    .then((res) => res.json())
                    .then((json) => {
                      if (json.error) {
                        if (json.error.message) alert(json.error.message);
                        setStatus("failed");
                      } else {
                        setStatus("sent");
                      }
                    });
                }}
              >
                {translate("Submit", language)}
              </button>
            )}
            {status === "sending" && (
              <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            )}
            {status === "sent" && (
              <div className="alert alert-primary" role="alert">
                {translate("Form submited", language)}
              </div>
            )}
            {status === "failed" && (
              <div className="alert alert-danger" role="alert">
                {translate("Form submit failed, please try later!", language)}
              </div>
            )}
            {status === "failed" && (
              <div className="alert alert-danger" role="alert">
                {translate("Form submit failed, please try later!", language)}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
