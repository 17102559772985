import React from "react";
import { useParams } from "react-router-dom";
import Layout from "../components/Layout";
import { translate } from "../languages";
import { API_URL, INFO_EMAIL, S3_URL } from "../consts";

export default function Contact() {
  const { language } = useParams();
  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [balcklist, setBlacklist] = React.useState<string[]>([]);

  React.useEffect(() => {
    fetch(`${S3_URL}blacklist/blacklist.json?timestamp=${new Date().getTime()}`)
      .then((res) => res.json())
      .then((json) => {
        setBlacklist(json);
      });
  }, []);

  return (
    <Layout>
      <>
        <div
          className="banner"
          style={{ backgroundImage: `url(${S3_URL}banner.jpg)` }}
        >
          {translate(
            "CONTACT US - NU STREAM REALTY (TORONTO) INC., BROKERAGE",
            language
          )}
        </div>
        <div className="paper">
          <div className="tag">{translate("SEND US AN EMAIL", language)}</div>
        </div>
        <div className="paper d-flex flex-column gap-10">
          <div className="fs-14 text-black-50 fw-bold">
            {translate(
              "If you have any questions, please fill out the contact form below and send us a message. We will get back to you within 24-48 hours. Thanks for your patience.",
              language
            )}
          </div>
          <div className="row w-100">
            <div className="col col-12 col-lg-6 d-flex flex-column gap-10">
              <input
                className="form-control"
                placeholder="Your Name"
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
              />
              <input
                className="form-control"
                placeholder="Your Email Address"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
              <input
                className="form-control"
                placeholder="Your Phone"
                value={phone}
                onChange={(e) => {
                  setPhone(e.target.value);
                }}
              />
            </div>
            <div className="col col-12 col-lg-6 d-flex flex-column gap-10">
              <textarea
                className="form-control"
                placeholder="Message"
                style={{ height: 135 }}
                onChange={(e) => {
                  setMessage(e.target.value);
                }}
              />
              <button
                className="btn-solid"
                style={{ marginBottom: 20 }}
                disabled={!name || !email || !phone || !message}
                onClick={() => {
                  if (
                    !balcklist.includes(email) &&
                    !balcklist.includes(phone) &&
                    !balcklist.includes(name)
                  ) {
                    const to = INFO_EMAIL;
                    const from = "hr@nustreamtoronto.com";
                    const cc = "admin@nustreamtoronto.com";
                    const subject = `${name} ${translate(
                      "SEND US AN EMAIL",
                      language
                    )}`;
                    const text = `Name: ${name}\n Email: ${email}\n Phone:${phone}\n Message:${message}`;
                    const html = `<div>Name: ${name}</div><div>Email: ${email}</div><div>Phone: ${phone}</div><div>Message: ${message}</div>`;
                    fetch(`${API_URL}mail`, {
                      method: "POST",
                      headers: {
                        "Content-Type": "application/json",
                        userName: localStorage.getItem("username") || "",
                      },
                      body: JSON.stringify({
                        from,
                        to,
                        cc,
                        subject,
                        text,
                        html,
                      }),
                    })
                      .then((res) => res.json())
                      .then((json) => {
                        if (!json.error) {
                          alert(`${translate("Email sent", language)}`);
                        }
                      });
                  }
                }}
              >
                {translate("SEND MESSAGE", language)}
              </button>
            </div>
          </div>
        </div>
        <div className="paper">
          <div className="tag">{translate("NU STREAM LOCATION", language)}</div>
        </div>
        <div className="paper d-flex flex-column gap-10">
          <div className="fs-14 text-black-50 fw-bold">
            {translate(
              "Find our location below on the Google map. Reach us by getting directions from the Google Map.",
              language
            )}
          </div>
          <div>
            <iframe
              title="map"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2877.385737529372!2d-79.37878868400595!3d43.847832979115026!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89d4d4ca76cdbfdd%3A0xb89bc390c5756014!2zTnUgU3RyZWFtIFJlYWx0eSBUb3JvbnRvIC0g5aSa5Lym5aSa5paw6LaL5Yq_5Zyw5Lqn!5e0!3m2!1sen!2sca!4v1505766840753"
              style={{ width: "100%", height: 400 }}
            ></iframe>
          </div>
        </div>
      </>
    </Layout>
  );
}
