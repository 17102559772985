import React from "react";
import { useParams } from "react-router-dom";
import Layout from "../components/Layout";
import BusinessCardOrderForm from "../components/BusinessCardOrderForm";
import SignageOrderForm from "../components/SignageOrderForm";
import FirstBrochureOrderForm from "../components/FirstBrochureOrderForm";
import BrochureReOrderForm from "../components/BrochureReOrderForm";
import SoldFlyerOrderForm from "../components/SoldFlyerOrderForm";

export default function Forms() {
  const { formName } = useParams();

  return (
    <Layout>
      <>
        {formName === "business-card-order-form" && <BusinessCardOrderForm />}
        {formName === "signage-order-form" && <SignageOrderForm />}
        {formName === "1st-brochure-order-form" && <FirstBrochureOrderForm />}
        {formName === "brochure-re-order-form" && <BrochureReOrderForm />}
        {formName === "sold-flyer-order-form" && <SoldFlyerOrderForm />}
      </>
    </Layout>
  );
}
