import React from "react";
import { useParams } from "react-router-dom";
import { API_URL, S3_URL } from "../consts";
import { translate } from "../languages";

export default function Files({
  folder,
  token,
}: {
  folder: string;
  token: string;
}) {
  const { language } = useParams();
  const [fileJson, setFileJson] = React.useState("[]");
  const [file, setFile] = React.useState("");
  const [fileName, setFileName] = React.useState("");
  const [files, setFiles] = React.useState([]);

  React.useEffect(() => {
    fetch(`${S3_URL}${folder}/${folder}.json?timestamp=${new Date().getTime()}`)
      .then((res) => res.json())
      .then((json) => {
        setFileJson(JSON.stringify(json, null, 2));
      });
  }, []);

  return (
    <div className="col col-12 d-flex flex-column gap-10">
      <textarea
        value={fileJson}
        rows={20}
        onChange={(e) => {
          setFileJson(e.target.value);
        }}
      ></textarea>
      <button
        className="btn-solid"
        onClick={() => {
          try {
            const Body = JSON.parse(fileJson);
            fetch(`${API_URL}s3put`, {
              method: "PUT",
              headers: { "Content-Type": "application/json", token },
              body: JSON.stringify({
                Key: `${folder}/${folder}.json`,
                Body,
              }),
            })
              .then((res) => res.json())
              .then((json) => {
                if (json.error) {
                  alert(json.error);
                } else {
                  setFileJson(JSON.stringify(Body, null, 2));
                  alert("Saved");
                }
              })
              .catch((ex) => {
                alert(ex);
              });
          } catch (e) {
            alert(e);
          }
        }}
      >
        {translate("SAVE", language)}
      </button>
      <div className="col col-12">
        <hr />
      </div>
      <div className="row">
        <div className="col col-6">
          <input
            className="form-control"
            type="file"
            accept="image/*,application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
            onChange={(e) => {
              if (e.target.files?.length === 1) {
                setFileName(e.target.files[0].name);
                const fileReader = new FileReader();
                fileReader.onloadend = (e) => {
                  if (e.target?.result) {
                    setFile(e.target?.result as string);
                  }
                };
                fileReader.readAsDataURL(e.target.files[0]);
              }
            }}
          />
        </div>
        <div className="col col-6">
          {file && fileName && (
            <button
              className="btn-solid w-100"
              onClick={() => {
                fetch(`${API_URL}s3put`, {
                  method: "PUT",
                  headers: {
                    "Content-Type": "application/json",
                    token,
                  },
                  body: JSON.stringify({
                    Key: `${folder}/${fileName}`,
                    Body: file,
                  }),
                })
                  .then((res) => res.json())
                  .then((json) => {
                    if (json.error) {
                      alert(json.error);
                    } else {
                      alert("Uploaded");
                      fetch(`${API_URL}s3list?Prefix=${folder}/`, {
                        method: "GET",
                        headers: {
                          "Content-Type": "application/json",
                          token,
                        },
                      })
                        .then((res) => res.json())
                        .then((json) => {
                          if (json.error) {
                            alert(json.error);
                          } else {
                            setFiles(json.data.Contents);
                          }
                        })
                        .catch((ex) => {
                          alert(ex);
                        });
                    }
                  })
                  .catch((ex) => {
                    alert(ex);
                  });
              }}
            >
              {translate("UPLOAD FILE", language)}
            </button>
          )}
        </div>
      </div>
      <div className="col col-12">
        <hr />
      </div>
      <div className="col col-12">
        <button
          className="btn-solid"
          onClick={() => {
            fetch(`${API_URL}s3list?Prefix=${folder}/`, {
              method: "GET",
              headers: { "Content-Type": "application/json", token },
            })
              .then((res) => res.json())
              .then((json) => {
                if (json.error) {
                  alert(json.error);
                } else {
                  setFiles(json.data.Contents);
                }
              })
              .catch((ex) => {
                alert(ex);
              });
          }}
        >
          {translate("SHOW FILES", language)}
        </button>
      </div>
      {files
        .filter(
          (file: any) =>
            file.Key !== `${folder}/` && file.Key !== `${folder}/${folder}.json`
        )
        .map((file: any) => (
          <div className="row" key={file.Key}>
            <div className="col col-12">
              <hr />
            </div>
            <div className="col col-10">
              <p>{`${S3_URL}${file.Key}`}</p>
              {file.Key.toLowerCase().endsWith(".jpg") ||
              file.Key.toLowerCase().endsWith(".jpeg") ||
              file.Key.toLowerCase().endsWith(".gif") ||
              file.Key.toLowerCase().endsWith(".png") ? (
                <img
                  src={`${S3_URL}${file.Key}`}
                  alt={file.Key}
                  style={{ height: "200px" }}
                />
              ) : (
                <a
                  href={`${S3_URL}${file.Key}`}
                  target="_blank"
                  className="link"
                >
                  {translate("OPEN FILE", language)}
                </a>
              )}
            </div>
            <div className="col col-2">
              <button
                className="btn-solid"
                onClick={() => {
                  fetch(`${API_URL}s3delete?Key=${file.Key}`, {
                    method: "DELETE",
                    headers: {
                      "Content-Type": "application/json",
                      token,
                    },
                  })
                    .then((res) => res.json())
                    .then((json) => {
                      if (json.error) {
                        alert(json.error);
                      } else {
                        fetch(`${API_URL}s3list?Prefix=${folder}/`, {
                          method: "GET",
                          headers: {
                            "Content-Type": "application/json",
                            token,
                          },
                        })
                          .then((res) => res.json())
                          .then((json) => {
                            if (json.error) {
                              alert(json.error);
                            } else {
                              setFiles(json.data.Contents);
                            }
                          })
                          .catch((ex) => {
                            alert(ex);
                          });
                      }
                    })
                    .catch((ex) => {
                      alert(ex);
                    });
                }}
              >
                {translate("DELETE FILE", language)}
              </button>
            </div>
          </div>
        ))}
    </div>
  );
}
