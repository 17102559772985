import React from "react";
import { useParams } from "react-router-dom";
import { RealtorType } from "../types";
import Modal from "./Modal";
import { API_URL, S3_URL } from "../consts";
import { translate } from "../languages";

export default function Teams() {
  const { language } = useParams();
  const [realtor, setRealtor] = React.useState<RealtorType | null>();
  const [realtors, setRealtors] = React.useState<RealtorType[]>([]);
  const [selected, setSelected] = React.useState<number | null>();

  React.useEffect(() => {
    fetch(`${API_URL}users`)
      .then((res) => res.json())
      .then((json) => {
        fetch(
          `${S3_URL}branches/branches.json?timestamp=${new Date().getTime()}`
        )
          .then((res1) => res1.json())
          .then((json1) => {
            setRealtors([...json, ...json1]);
          });
      });
  }, []);

  const showRealtor = (r: RealtorType) => (
    <div key={`realtor-${r.id}`} className="col col-sm-12 col-md-6 col-lg-3">
      <div className="paper p-3">
        <div className="d-flex flex-column justify-content-center w-100">
          <div
            className="photo"
            style={{
              height: "300px",
              minWidth: "200px",
              backgroundImage: `url(${encodeURI(r.photo)})`,
            }}
            onMouseEnter={() => {
              setSelected(r.id);
            }}
            onMouseLeave={() => {
              setSelected(null);
            }}
          >
            <div
              className="div-details"
              style={{
                width: "100%",
                height: "100%",
                backgroundColor: "rgb(0 0 0 / 25%)",
                display: selected === r.id ? "block" : "none",
              }}
            >
              <a href={`/${language}/realtor/${r.id}/`} className="btn-outline">
                {translate("More Details", language)}
              </a>
            </div>
          </div>
          <div className="text-center text-uppercase">{`${r.firstName} ${r.lastName}`}</div>
          <div className="text-center text-ns">
            {r.realtor?.licensedAs || <span>&nbsp;</span>}
          </div>
          {r.teamMembers && r.teamMembers.length > 0 && (
            <>
              <div className="d-flex">
                <a href={`mailto:${r.email}`}>
                  <i className="fa fa-envelope text-ns"></i>
                </a>
                <div className="text-black-50 text-truncate">
                  &nbsp;{r.email}
                </div>
              </div>
              <div className="d-flex">
                <a href={`tel:${r.phone}`}>
                  <i className="fa fa-phone text-ns"></i>
                </a>
                <div className="text-black-50 text-truncate">
                  &nbsp;{r.phone}
                </div>
              </div>
              <hr />
              <div className="text-center" style={{ height: 40 }}>
                <button
                  className="btn-solid"
                  onClick={() => {
                    setRealtor(r);
                  }}
                >
                  {translate(r.realtor?.title || "TEAM MEMBERS", language)}
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );

  return (
    <div className="row">
      {realtors
        .filter(
          (r) =>
            r.realtor &&
            r.photo &&
            r.status !== "DISABLED" &&
            r.teamMembers &&
            r.teamMembers.length > 0
        )
        .map((r) => showRealtor(r))}
      {realtor && realtor.teamMembers && realtor.teamMembers.length > 0 && (
        <Modal
          onClick={() => {
            setRealtor(null);
          }}
        >
          <div className="row">
            {realtor.teamMembers
              .filter(
                (member) =>
                  member.realtor &&
                  member.photo &&
                  member.status !== "DISABLED" &&
                  member.id !== realtor.id
              )
              .map((member) => showRealtor(member))}
          </div>
        </Modal>
      )}
    </div>
  );
}
