import React from "react";
import { useParams } from "react-router-dom";
import { translate } from "../languages";
import { API_URL, DESIGNER_EMAIL, S3_URL } from "../consts";

export default function SignageOrderForm() {
  const { language } = useParams();
  const [form, setForm] = React.useState<{ [key: string]: any }>({
    SUBJECT: "Signage Order Form",
  });
  const [status, setStatus] = React.useState("");
  const [teams, setTeams] = React.useState<{ order: number; name: string }[]>(
    []
  );

  React.useEffect(() => {
    fetch(`${S3_URL}teams/teams.json?timestamp=${new Date().getTime()}`)
      .then((res) => res.json())
      .then((json) => {
        setTeams(json);
      });
  }, []);

  React.useEffect(() => {
    if (
      form["TRADE NAME"] &&
      form["TEAM NAME"] &&
      form["CELL PHONE"] &&
      (form["OPEN HOUSE SIGN - 24*28 in"] ||
        form["OPEN HOUSE SIGN - 18*24 in 4mm"] ||
        form["FOR SALE SIGN"] ||
        form["FOR LEASE SIGN"] ||
        form["RIDER SIGN"])
    ) {
      setStatus("sendable");
    } else {
      setStatus("");
    }
  }, [form]);

  return (
    <>
      <div className="paper">
        <div className="tag text-capitalize">
          {translate("Signage Order Form", language)}
        </div>
      </div>
      <div className="paper d-flex flex-column gap-10">
        <div className="row w-100">
          <div className="col col-12 col-lg-6 p-3">
            <label className="form-label">
              {translate("TRADE NAME*", language)}
            </label>
            <input
              className="form-control"
              value={form["TRADE NAME"] || ""}
              onChange={(e) => {
                setForm({ ...form, "TRADE NAME": e.target.value });
              }}
            />
          </div>
          <div className="col col-12 col-lg-6 p-3">
            <label className="form-label">
              {translate("FOREIGN NAME", language)}
            </label>
            <input
              className="form-control"
              value={form["FOREIGN NAME"] || ""}
              onChange={(e) => {
                setForm({ ...form, "FOREIGN NAME": e.target.value });
              }}
            />
          </div>
          <div className="col col-12 col-lg-6 p-3">
            <label className="form-label">
              {translate("TEAM NAME*", language)}
            </label>
            <select
              className="form-select"
              value={form["TEAM NAME"] || ""}
              onChange={(e) => {
                setForm({ ...form, "TEAM NAME": e.target.value });
              }}
            >
              <option value="" disabled></option>
              {teams
                .sort((a, b) => a.order - b.order)
                .map(({ name }) => (
                  <option value={name}>{name}</option>
                ))}
            </select>
          </div>
          <div className="col col-12 col-lg-6 p-3">
            <label className="form-label">
              {translate("CELL PHONE*", language)}
            </label>
            <input
              className="form-control"
              value={form["CELL PHONE"] || ""}
              onChange={(e) => {
                setForm({ ...form, "CELL PHONE": e.target.value });
              }}
            />
          </div>
          <div className="col col-12">
            <div className="row">
              <div className="col col-12 col-lg-3 p-3 d-flex flex-column">
                <label className="form-label">
                  {translate("How to get JoHome Code", language)}
                </label>
                <img
                  src={`${S3_URL}qrcodeSample1.png`}
                  alt=""
                  style={{ width: 250 }}
                />
              </div>
              <div className="col col-12 col-lg-3 p-3 d-flex flex-column">
                <label className="form-label">
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </label>
                <img
                  src={`${S3_URL}qrcodeSample2.png`}
                  alt=""
                  style={{ width: 250 }}
                />
              </div>
              <div className="col col-12 col-lg-3 p-3 d-flex flex-column">
                <label className="form-label">
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </label>
                <img
                  src={`${S3_URL}qrcodeSample3.png`}
                  alt=""
                  style={{ width: 250 }}
                />
              </div>
              <div className="col col-12 col-lg-3 p-3 d-flex flex-column">
                <label className="form-label">
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </label>
                <img
                  src={`${S3_URL}qrcodeSample4.png`}
                  alt=""
                  style={{ width: 250 }}
                />
              </div>
            </div>
          </div>
          <div className="col col-12 p-3">
            <div className="row">
              <div className="col col-12 col-lg-6 p-3">
                <label className="switch align-middle">
                  <input
                    type="checkbox"
                    onChange={(e) => {
                      setForm({
                        ...form,
                        "OPEN HOUSE SIGN - 24*28 in": e.target.checked
                          ? "24"
                          : undefined,
                      });
                    }}
                  />
                  <span className="slider round"></span>
                </label>
                <label className="form-label">
                  {translate("OPEN HOUSE SIGN - 24*28 in", language)}
                </label>
              </div>
              <div className="col col-12 col-lg-6 p-3">
                {form["OPEN HOUSE SIGN - 24*28 in"] && (
                  <select
                    className="form-select"
                    value={form["OPEN HOUSE SIGN - 24*28 in"] || ""}
                    onChange={(e) => {
                      setForm({
                        ...form,
                        "OPEN HOUSE SIGN - 24*28 in": e.target.value,
                      });
                    }}
                  >
                    <option value=""></option>
                    <option value="6">6</option>
                    <option value="12">12</option>
                    <option value="18">18</option>
                    <option value="24">24</option>
                    <option value="Digital copy">Digital copy</option>
                    <option value="No Frame (confirm quantity with Designer)">
                      No Frame (confirm quantity with Designer)
                    </option>
                  </select>
                )}
              </div>
            </div>
          </div>
          <div className="col col-12 p-3">
            <div className="row">
              <div className="col col-12 col-lg-6 p-3">
                <label className="switch align-middle">
                  <input
                    type="checkbox"
                    onChange={(e) => {
                      setForm({
                        ...form,
                        "OPEN HOUSE SIGN - 18*24 in 4mm": e.target.checked
                          ? "6"
                          : undefined,
                      });
                    }}
                  />
                  <span className="slider round"></span>
                </label>
                <label className="form-label">
                  {translate("OPEN HOUSE SIGN - 18*24 in 4mm", language)}
                </label>
              </div>
              <div className="col col-12 col-lg-6 p-3">
                {form["OPEN HOUSE SIGN - 18*24 in 4mm"] && (
                  <select
                    className="form-select"
                    value={form["OPEN HOUSE SIGN - 18*24 in 4mm"] || ""}
                    onChange={(e) => {
                      setForm({
                        ...form,
                        "OPEN HOUSE SIGN - 18*24 in 4mm": e.target.value,
                      });
                    }}
                  >
                    <option value=""></option>
                    <option value="6">6</option>
                    <option value="12">12</option>
                    <option value="18">18</option>
                    <option value="24">24</option>
                    <option value="Digital copy">Digital copy</option>
                    <option value="No Frame (confirm quantity with Designer)">
                      No Frame (confirm quantity with Designer)
                    </option>
                  </select>
                )}
              </div>
            </div>
          </div>
          <div className="col col-12 p-3">
            <div className="row">
              <div className="col col-12 col-lg-6 p-3">
                <label className="switch align-middle">
                  <input
                    type="checkbox"
                    onChange={(e) => {
                      setForm({
                        ...form,
                        "FOR SALE SIGN": e.target.checked
                          ? "Digital Copy"
                          : undefined,
                      });
                    }}
                  />
                  <span className="slider round"></span>
                </label>
                <label className="form-label">
                  {translate("FOR SALE SIGN", language)}
                </label>
              </div>
              <div className="col col-12 col-lg-6 p-3">
                {form["FOR SALE SIGN"] && (
                  <select
                    className="form-select"
                    value={form["FOR SALE SIGN"] || ""}
                    onChange={(e) => {
                      setForm({ ...form, "FOR SALE SIGN": e.target.value });
                    }}
                  >
                    <option value=""></option>
                    <option value="Digital Copy">Digital Copy</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                    <option value="8">8</option>
                    <option value="9">9</option>
                    <option value="10">10</option>
                  </select>
                )}
              </div>
            </div>
          </div>
          <div className="col col-12 p-3">
            <div className="row">
              <div className="col col-12 col-lg-6 p-3">
                <label className="switch align-middle">
                  <input
                    type="checkbox"
                    onChange={(e) => {
                      setForm({
                        ...form,
                        "FOR LEASE SIGN": e.target.checked
                          ? "Digital Copy"
                          : undefined,
                      });
                    }}
                  />
                  <span className="slider round"></span>
                </label>
                <label className="form-label">
                  {translate("FOR LEASE SIGN", language)}
                </label>
              </div>
              <div className="col col-12 col-lg-6 p-3">
                {form["FOR LEASE SIGN"] && (
                  <select
                    className="form-select"
                    value={form["FOR LEASE SIGN"] || ""}
                    onChange={(e) => {
                      setForm({ ...form, "FOR LEASE SIGN": e.target.value });
                    }}
                  >
                    <option value=""></option>
                    <option value="Digital Copy">Digital Copy</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                    <option value="8">8</option>
                    <option value="9">9</option>
                    <option value="10">10</option>
                  </select>
                )}
              </div>
            </div>
          </div>
          <div className="col col-12 p-3">
            <div className="row">
              <div className="col col-12 col-lg-6 p-3">
                <label className="switch align-middle">
                  <input
                    type="checkbox"
                    onChange={(e) => {
                      setForm({
                        ...form,
                        "RIDER SIGN": e.target.checked ? "Sold" : undefined,
                      });
                    }}
                  />
                  <span className="slider round"></span>
                </label>
                <label className="form-label">
                  {translate("RIDER SIGN", language)}
                </label>
              </div>
              <div className="col col-12 col-lg-6 p-3">
                {form["RIDER SIGN"] && (
                  <select
                    className="form-select"
                    value={form["RIDER SIGN"] || ""}
                    onChange={(e) => {
                      setForm({ ...form, "RIDER SIGN": e.target.value });
                    }}
                  >
                    <option value=""></option>
                    <option value="Sold">Sold</option>
                    <option value="Leased">Leased</option>
                    <option value="Sold Over asking">Sold Over asking</option>
                    <option value="Exclusive">Exclusive</option>
                    <option value="Coming Soon On MLS">
                      Coming Soon On MLS
                    </option>
                    <option value="Open House Sat,Sun 2pm-4pm">
                      Open House Sat,Sun 2pm-4pm
                    </option>
                    <option value="Open House Sat,Sun 2pm-5pm">
                      Open House Sat,Sun 2pm-5pm
                    </option>
                    <option value="12+18 Servie">12+18 Servie</option>
                  </select>
                )}
              </div>
            </div>
          </div>
          <div className="col col-12 p-3">
            {status === "" && (
              <button
                className="btn-solid"
                style={{ backgroundColor: "#000000" }}
                disabled
              >
                Submit
              </button>
            )}
            {status === "sendable" && (
              <button
                className="btn-solid"
                onClick={() => {
                  setStatus("sending");
                  const to = DESIGNER_EMAIL;
                  const from = "hr@nustreamtoronto.com";
                  const cc = "admin@nustreamtoronto.com";
                  const subject = form["SUBJECT"];
                  const text = JSON.stringify(form);
                  let html =
                    '<table style="width:100%;border: 1px solid;border-collapse: collapse;">';

                  const formProperties = [
                    "SUBJECT",
                    "TRADE NAME",
                    "FOREIGN NAME",
                    "TEAM NAME",
                    "CELL PHONE",
                    "OPEN HOUSE SIGN - 24*28 in",
                    "OPEN HOUSE SIGN - 18*24 in 4mm",
                    "FOR SALE SIGN",
                    "FOR LEASE SIGN",
                    "RIDER SIGN",
                  ];

                  for (const formProperty of formProperties) {
                    if (form[formProperty]) {
                      html =
                        html +
                        '<tr><td style="width:40%;border: 1px solid;border-collapse: collapse;">' +
                        formProperty +
                        '</td><td style="width:60%;border: 1px solid;border-collapse: collapse;">' +
                        form[formProperty] +
                        "</td></tr>";
                    }
                  }

                  html = html + "</table>";
                  fetch(`${API_URL}mail`, {
                    method: "POST",
                    headers: {
                      "Content-Type": "application/json",
                      userName: localStorage.getItem("username") || "",
                    },
                    body: JSON.stringify({ from, to, cc, subject, text, html }),
                  })
                    .then((res) => res.json())
                    .then((json) => {
                      if (json.error) {
                        if (json.error.message) alert(json.error.message);
                        setStatus("failed");
                      } else {
                        setStatus("sent");
                      }
                    });
                }}
              >
                {translate("Submit", language)}
              </button>
            )}
            {status === "sending" && (
              <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            )}
            {status === "sent" && (
              <div className="alert alert-primary" role="alert">
                {translate("Form submited", language)}
              </div>
            )}
            {status === "failed" && (
              <div className="alert alert-danger" role="alert">
                {translate("Form submit failed, please try later!", language)}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
