import React from "react";
import { useParams } from "react-router-dom";
import Layout from "../components/Layout";
import { S3_URL } from "../consts";
import { translate } from "../languages";

export default function Dashboard() {
  const { language } = useParams();
  const username = localStorage.getItem("username");
  const [files, setFiles] = React.useState<any>({});

  React.useEffect(() => {
    fetch(`${S3_URL}files/files.json?timestamp=${new Date().getTime()}`)
      .then((res) => res.json())
      .then((json) => {
        setFiles(json);
      });
  }, [language]);

  if (!username) {
    window.location.href = `/${language}/login`;
  }

  return (
    <Layout>
      <>
        <div
          className="banner"
          style={{ backgroundImage: `url(${S3_URL}banner.jpg)` }}
        >
          {translate(
            "AGENT CENTER - NU STREAM REALTY (TORONTO) INC., BROKERAGE",
            language
          )}
        </div>
        <div className="paper d-flex flex-column gap-10">
          <div className="w-100 text-center fs-3">
            {translate("ORDER FORMS", language)}
          </div>
          <div className="w-100 text-center fs-6 text-black-50">
            {translate("For Designer", language)}
          </div>
          <a href={`/${language}/forms/business-card-order-form/`}>
            <div className="w-100 text-center btn-solid">
              {translate("Business Card Order Form", language)}
            </div>
          </a>
          <a href={`/${language}/forms/signage-order-form/`}>
            <div className="w-100 text-center btn-solid">
              {translate("Signage Order Form", language)}
            </div>
          </a>
          <a href={`/${language}/forms/1st-brochure-order-form/`}>
            <div className="w-100 text-center btn-solid">
              {translate("1st Brochure Order Form", language)}
            </div>
          </a>
          <a href={`/${language}/forms/brochure-re-order-form/`}>
            <div className="w-100 text-center btn-solid">
              {translate("Brochure Reorder Form (NOT FOR 1st ORDER)", language)}
            </div>
          </a>
          <a href={`/${language}/forms/sold-flyer-order-form/`}>
            <div className="w-100 text-center btn-solid">
              {translate("Sold Flyer Order Form", language)}
            </div>
          </a>
          <br />
        </div>
        <div className="paper d-flex flex-column gap-10">
          <div className="w-100 text-center fs-3">
            {translate("FORMS FOR LISTING/BUYING AGENT", language)}
          </div>
          <div className="w-100 text-center fs-6 text-black-50">
            {translate("For Deal Secretary", language)}
          </div>

          <div className="row w-100">
            {files["FORMS FOR LISTING/BUYING AGENT"]?.map((file: any) => (
              <div className="col col-12 col-lg-6 p-2">
                <button
                  className="w-100 text-center btn-solid"
                  onClick={async () => {
                    if (file.url.includes("http")) {
                      window.open(file.url);
                    } else {
                      const response = await fetch(file.url);
                      const blob = await response.blob();
                      const url = window.URL.createObjectURL(blob);
                      window.open(url);
                    }
                  }}
                >
                  {translate(file.name, language)}
                </button>
              </div>
            ))}
          </div>
        </div>
        <div className="paper d-flex flex-column gap-10">
          <div className="w-100 text-center fs-3">
            {translate("FINTRAC INFORMATION", language)}
          </div>
          <div className="row w-100">
            {files["FINTRAC INFORMATION"]?.map((file: any) => (
              <div className="col col-12 col-lg-4 p-2">
                <button
                  className="w-100 text-center btn-solid"
                  onClick={async () => {
                    if (file.url.includes("http")) {
                      window.open(file.url);
                    } else {
                      const response = await fetch(file.url);
                      const blob = await response.blob();
                      const url = window.URL.createObjectURL(blob);
                      window.open(url);
                    }
                  }}
                >
                  {translate(file.name, language)}
                </button>
              </div>
            ))}
          </div>
        </div>
        <div className="row w-100">
          <div className="col col-12 col-lg-6 p-2">
            <div className="paper d-flex flex-column gap-10">
              <div className="w-100 text-center fs-3">
                {translate("OFFICE INSTRUCTION", language)}
              </div>
              <div className="w-100 text-center fs-6 text-black-50">
                {translate(
                  "CONTACT PERSON , EMAIL ADDRESS, GUIDLINES",
                  language
                )}
              </div>
              <div className="row w-100">
                {files["OFFICE INSTRUCTION"]?.map((file: any) => (
                  <div className="col col-12 col-lg-6 p-2">
                    <button
                      className="w-100 text-center btn-solid"
                      onClick={async () => {
                        if (file.url.includes("http")) {
                          window.open(file.url);
                        } else {
                          const response = await fetch(file.url);
                          const blob = await response.blob();
                          const url = window.URL.createObjectURL(blob);
                          window.open(url);
                        }
                      }}
                    >
                      {translate(file.name, language)}
                    </button>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="col col-12 col-lg-6 p-2">
            <div className="paper d-flex flex-column gap-10">
              <div className="w-100 text-center fs-3">
                {translate("OPEN HOUSE FORM", language)}
              </div>
              <div className="w-100 text-center fs-6 text-black-50">
                {translate("OPEN HOUSE REGISTER FORM", language)}
              </div>
              <div className="row w-100">
                {files["OPEN HOUSE FORM"]?.map((file: any) => (
                  <div className="col col-12 col-lg-6 p-2">
                    <button
                      className="w-100 text-center btn-solid"
                      onClick={async () => {
                        if (file.url.includes("http")) {
                          window.open(file.url);
                        } else {
                          const response = await fetch(file.url);
                          const blob = await response.blob();
                          const url = window.URL.createObjectURL(blob);
                          window.open(url);
                        }
                      }}
                    >
                      {translate(file.name, language)}
                    </button>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="row w-100">
          {files["40 REAL ESTATE OBJECTIONS HANDLED"]?.map((file: any) => (
            <div className="col col-12 col-lg-6 p-2">
              <button
                className="w-100 text-center btn-blue fs-4"
                onClick={async () => {
                  if (file.url.includes("http")) {
                    window.open(file.url);
                  } else {
                    const response = await fetch(file.url);
                    const blob = await response.blob();
                    const url = window.URL.createObjectURL(blob);
                    window.open(url);
                  }
                }}
              >
                {translate(file.name, language)}
              </button>
            </div>
          ))}
          {files["Information for Leasing (Download)"]?.map((file: any) => (
            <div className="col col-12 col-lg-6 p-2">
              <button
                className="w-100 text-center btn-blue fs-4"
                onClick={async () => {
                  if (file.url.includes("http")) {
                    window.open(file.url);
                  } else {
                    const response = await fetch(file.url);
                    const blob = await response.blob();
                    const url = window.URL.createObjectURL(blob);
                    window.open(url);
                  }
                }}
              >
                {translate(file.name, language)}
              </button>
            </div>
          ))}
        </div>
        <div className="row w-100">
          <div className="col col-12 col-lg-4 p-2"></div>
          <div className="col col-12 col-lg-4 p-2">
            <button
              className="w-100 text-center btn-solid fs-4"
              onClick={() => {
                localStorage.removeItem("username");
                window.location.href = `/${language}/login`;
              }}
            >
              {translate("LOGOUT", language)}
            </button>
          </div>
          <div className="col col-12 col-lg-4 p-2"></div>
        </div>
      </>
    </Layout>
  );
}
