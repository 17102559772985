import React from "react";
import { useParams } from "react-router-dom";
import Layout from "../components/Layout";
import Teams from "../components/Teams";
import { S3_URL } from "../consts";
import { translate } from "../languages";

export default function Home() {
  const { language } = useParams();
  const [bannerIndex, setBannerIndex] = React.useState(0);
  const [banners, setBanners] = React.useState<{ url: string }[]>([]);
  const [opacity, setOpacity] = React.useState(0);
  const [reviewIndex, setReviewIndex] = React.useState(0);
  const reviews = [
    {
      url: `${S3_URL}review1.jpg`,
      name: "",
      title: "Office Admin / Media Mac",
      type: "Client Review",
      content:
        "We moved to Toronto in Aug 2016. We heard of Nu Stream Realty from my friend and tried to contact them at a first time. The agent gave us excellent guidance, unflagging availability, and deep knowledge about a really wide range of homes that we looked at. And we couldn’t have been more satisfied with the process.",
    },
    {
      url: `${S3_URL}review2.jpg`,
      name: "Rebecca Dee",
      title: "CEO & Co-Founder / PQR Enterprises",
      type: "Client Experience",
      content:
        "I contacted with Nu Stream to purchase our first home and really can’t say enough good things about her. They are knowledgeable, personable, endlessly patient, and incredibly generous with her time (including mornings, evenings and weekends over the course of several months).",
    },
    {
      url: `${S3_URL}review3.jpg`,
      name: "Sara Jones",
      title: "Marketing Lead / ABC International",
      type: "New Landing",
      content:
        "We had a great experience working with NuStream. I couldn’t believe how responsive they were to all of our requests and questions, and they knew far more than we ever hoped to about the process of buying a home. All things went well. Thank you very much.",
    },
    {
      url: `${S3_URL}review4.jpg`,
      name: "David Greer",
      title: "Director & Founder / XYZ Company",
      type: "Client Comment",
      content:
        "Nu Stream helped me buy a home in Mississauga. This was the first time buying a house in Canada. Nu Stream's agent was very patient and helpful, and maintained great communication throughout the entire process. I would highly recommend them to my friends.",
    },
  ];

  React.useEffect(() => {
    fetch(
      `${S3_URL}banners${language}/banners${language}.json?timestamp=${new Date().getTime()}`
    )
      .then((res) => res.json())
      .then((json) => {
        const today = new Date();
        const bs = json.filter(
          (banner: { url: string; start: string; end: string }) => {
            if (banner.url) {
              if (banner.start && banner.end) {
                const start = new Date(banner.start);
                const end = new Date(banner.end);
                if (start < today && today < end) {
                  return true;
                }
              } else {
                return true;
              }
            }
            return false;
          }
        );
        setBanners(bs);
      });
  }, [language]);

  React.useEffect(() => {
    const interval = setInterval(() => {
      setBannerIndex((prevBannerIndex) => prevBannerIndex + 1);
      setReviewIndex((prevReviewIndex) => prevReviewIndex + 1);
    }, 6000);
    return () => clearInterval(interval);
  }, []);

  return (
    <Layout>
      <>
        {banners && banners.length > 0 && (
          <div
            style={{ position: "relative", margin: "5px 0px" }}
            onMouseEnter={() => {
              setOpacity(0.5);
            }}
            onMouseLeave={() => {
              setOpacity(0);
            }}
          >
            <img
              src={banners[bannerIndex % banners.length].url}
              className="w-100"
              alt=""
            />
            <div
              style={{
                position: "absolute",
                left: "0px",
                top: "50%",
                fontSize: "30px",
                color: "#ffffff",
                opacity,
                cursor: "pointer",
              }}
              onClick={() => {
                setBannerIndex(bannerIndex + banners.length - 1);
              }}
            >
              <i className="fa-solid fa-circle-arrow-left"></i>
            </div>
            <div
              style={{
                position: "absolute",
                right: "0px",
                top: "50%",
                fontSize: "30px",
                color: "#ffffff",
                opacity,
                cursor: "pointer",
              }}
              onClick={() => {
                setBannerIndex(bannerIndex + 1);
              }}
            >
              <i className="fa-sharp fa-solid fa-circle-arrow-right"></i>
            </div>
          </div>
        )}
        <div
          className="contact"
          style={{ backgroundImage: `url(${S3_URL}contact.jpg)` }}
        >
          <div
            style={{
              backgroundColor: "rgb(0 0 0 /50%)",
              width: "100%",
              height: "100%",
              textAlign: "center",
              color: "#ffffff",
              padding: "100px 0px",
              fontSize: "36px",
              fontWeight: 600,
            }}
          >
            <p className="fs-1">
              {translate("NO PROPERTY IS HARD TO SELL", language)}
            </p>
            <p className="fs-3">
              {translate(
                "NU STREAM PROVIDES YOU WITH THE BEST SERVICES",
                language
              )}
            </p>
            <a
              href={`/${language}/contact/`}
              className="btn-solid bg-white text-black-50"
            >
              {translate("CONTACT US", language)}
            </a>
          </div>
        </div>
        <div className="paper">
          <div className="tag">
            {translate(
              "ELITE TEAMS IN GREATER TORONTO AREA TO SERVE YOU",
              language
            )}
          </div>
        </div>
        <Teams />
        {reviews && reviews.length > 0 && (
          <div
            className="reviews p-5"
            style={{ backgroundImage: `url(${S3_URL}reviews.jpg)` }}
            onMouseEnter={() => {
              setOpacity(0.5);
            }}
            onMouseLeave={() => {
              setOpacity(0);
            }}
          >
            <div
              className="row p-3"
              style={{ backgroundColor: "rgb(255,255,255,40%)" }}
            >
              <div className="col col-12 col-lg-6 p-3">
                <img
                  style={{
                    borderRadius: "60px",
                    width: "120px",
                    height: "120px",
                    border: "5px solid white",
                  }}
                  src={reviews[reviewIndex % reviews.length].url}
                  alt=""
                />
                <div className="fw-bold">
                  {translate(
                    reviews[reviewIndex % reviews.length].name,
                    language
                  )}
                </div>
                <div className="text-black-50">
                  {translate(
                    reviews[reviewIndex % reviews.length].title,
                    language
                  )}
                </div>
                <div className="text-black-50 fs-5">
                  {translate(
                    reviews[reviewIndex % reviews.length].type,
                    language
                  )}
                </div>
                <p className="text-black-50">
                  <em>
                    {translate(
                      reviews[reviewIndex % reviews.length].content,
                      language
                    )}
                  </em>
                </p>
              </div>
              <div className="col col-12 col-lg-6 p-3 d-none d-lg-block">
                <img
                  style={{
                    borderRadius: "60px",
                    width: "120px",
                    height: "120px",
                    border: "5px solid white",
                  }}
                  src={reviews[(reviewIndex + 1) % reviews.length].url}
                  alt=""
                />
                <div className="fw-bold">
                  {translate(
                    reviews[(reviewIndex + 1) % reviews.length].name,
                    language
                  )}
                </div>
                <div className="text-black-50 fs-5">
                  {translate(
                    reviews[(reviewIndex + 1) % reviews.length].title,
                    language
                  )}
                </div>
                <div className="text-black-50 fs-5">
                  {translate(
                    reviews[(reviewIndex + 1) % reviews.length].type,
                    language
                  )}
                </div>
                <p className="text-black-50">
                  <em>
                    {translate(
                      reviews[(reviewIndex + 1) % reviews.length].content,
                      language
                    )}
                  </em>
                </p>
              </div>
            </div>
            <div
              style={{
                position: "absolute",
                left: "0px",
                top: "50%",
                fontSize: "30px",
                color: "#ffffff",
                opacity,
                cursor: "pointer",
              }}
              onClick={() => {
                setReviewIndex(reviewIndex + reviews.length - 1);
              }}
            >
              <i className="fa-solid fa-circle-arrow-left"></i>
            </div>
            <div
              style={{
                position: "absolute",
                right: "0px",
                top: "50%",
                fontSize: "30px",
                color: "#ffffff",
                opacity,
                cursor: "pointer",
              }}
              onClick={() => {
                setReviewIndex(reviewIndex + 1);
              }}
            >
              <i className="fa-sharp fa-solid fa-circle-arrow-right"></i>
            </div>
          </div>
        )}
        <div className="paper">
          <div className="tag">{translate("ABOUT NUSTREAM", language)}</div>
          <a href={`/${language}/about/`} className="float-right link">
            {translate("KNOW MORE", language)}
          </a>
        </div>
        <div className="paper">
          <div className="row w-100 p-3">
            <div className="col col-md-12 col-lg-6 p-3">
              <h5>{translate("How It Started", language)}</h5>
              <p className="text-black-50 fs-14 fw-bold">
                {translate(
                  "Founded in 2015, Nu Stream is a real estate company under Lenovo Group and landed in Toronto in August 2016. As of February 22, 2017, the sales of Nu Stream Toronto officially exceeded 100 million, reaching 107,951,165 Canadian dollars.",
                  language
                )}
              </p>
              <p className="text-black-50 fs-14 fw-bold">
                {translate(
                  "At the same time, Nu Stream is trying to become a bridge connecting the housing markets of China and Canada, and plans to expand overseas markets.",
                  language
                )}
              </p>
              <p className="text-black-50 fs-14 fw-bold">
                {translate(
                  "The main business scope of Nu Stream covers real estate investment, commercial real estate sales, market strategy planning, investment analysis and real estate qualification certificate training, etc.",
                  language
                )}
              </p>
              <p className="text-black-50 fs-14 fw-bold">
                {translate(
                  "Currently, Nu Stream has established 11 teams in the Vancouver area and 8 teams in the Greater Toronto Area, aiming to comprehensively provide project marketing services for all real estate industries (including industrial, office, retail and residential).",
                  language
                )}
              </p>
              <button
                className="btn-solid"
                onClick={() => {
                  window.location.href = `/${language}/about/`;
                }}
              >
                {translate("VIEW MORE", language)}
              </button>
            </div>
            <div className="col col-12 col-lg-6 p-3">
              <img src={`${S3_URL}banner.jpg`} className="w-100 " alt="" />
            </div>
          </div>
        </div>
      </>
    </Layout>
  );
}
