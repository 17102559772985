export const CN: { [key: string]: string } = {
  HOME: "主页",
  "ABOUT US": "关于我们",
  "OUR TEAM": "我们团队",
  "LISTING INFO": "房产信息",
  "CONTACT US": "联系我们",
  "NO PROPERTY IS HARD TO SELL": "让天下没有难卖的房！",
  "ELITE TEAMS IN GREATER TORONTO AREA TO SERVE YOU":
    "大多地区精英团队为您服务",
  "ABOUT NUSTREAM": "关于新趋势地产",
  "KNOW MORE": "查看更多",
  "VIEW MORE": "更多信息",
  "VIEW OUR AGENTS": "查看更多",
  "NU STREAM PROVIDES YOU WITH THE BEST SERVICES":
    "NU STREAM 为您提供最好的服务",
  "How It Started": "新趋势发展史",
  "Founded in 2015, Nu Stream is a real estate company under Lenovo Group and landed in Toronto in August 2016. As of February 22, 2017, the sales of Nu Stream Toronto officially exceeded 100 million, reaching 107,951,165 Canadian dollars.":
    "新趋势地产成立于2015年，是联想集团旗下的地产公司，于2016年8月登陆多伦多。截止至2017年2月22日，新趋势地产多伦多公司的销售额正式破亿，达到107，951，165加币。",
  "At the same time, Nu Stream is trying to become a bridge connecting the housing markets of China and Canada, and plans to expand overseas markets.":
    "与此同时，新趋势地产尝试着成为联通中加房屋市场的桥梁，并计划开拓海外市场。",
  "The main business scope of Nu Stream covers real estate investment, commercial real estate sales, market strategy planning, investment analysis and real estate qualification certificate training, etc.":
    "新趋势地产的主要的业务范围涵盖了房地产投资、商业地产买卖、市场战略策划、投资分析及地产从业资格证培训等多个方面。",
  "Currently, Nu Stream has established 11 teams in the Vancouver area and 8 teams in the Greater Toronto Area, aiming to comprehensively provide project marketing services for all real estate industries (including industrial, office, retail and residential).":
    "目前，新趋势地产在温哥华地区组建了11个团队，在大多伦多地区组建了8个团队，旨在全面地为所有房地产行业（包括工业、办公、零售和住宅）提供项目营销服务。",
  "ABOUT US - NU STREAM REALTY (TORONTO) INC., BROKERAGE":
    "多伦多地产公司 - 新趋势地产 - NU STREAM REALTY 助力您的房屋买卖",
  "ABOUT NU STREAM": "关于新趋势",
  "COMPANY BACKGROUND": "关于我们",
  "Nu Stream Realty was founded December 2015 in Vancouver (Nu Stream Realty [Toronto] Inc. Brokerage licensed August 2016 in Ontario). It came with a strong investment and numerous resources which associated to the Lenovo Group. Our powerful background is giving us such support for Nu Stream Realty to do something completely different compared to traditional real estate brokerage. As a pioneer of the new model of real estate brokerage, Nu Stream Realty has such a great start.":
    "新趋势地产成立于2015年，是联想系投资创新模式地产公司，于2016年8月登陆多伦多。截止至2018年6月，新趋势地产公司在大温哥华地区组有17个团队，在大多伦多地区有12大团队，包括10大地区团队、商业地产团队及楼花团队。主要的业务范围涵盖了新房及二手房买卖、商业地产买卖、投资分析等多个方面，旨在全多伦多为所有地产行业提供专业服务。",
  "At the end of December 2017, Nu Stream Realty has over 350 local Real Estate Agents in Vancouver and Toronto with over 500 active listings, and the number is increasing continually. We had more than 3407 deals completed less than three years. Our sales turnover is more than 2.23 billion Canadian Dollars during 2017. Even with this milestone, Nu Stream Realty continues to plan ahead for new achievements. We have established itself upon bridging the Real Estate Services between China and Canada which has further expanded to the overseas market.":
    " ",
  "OUR STRENGTH": "我们的实力",
  "We can get your home sold the fastest and at the highest price in the shortest time on the market, and the sales image of the property can also reach the top level in the market. The tenet of THE WHOLE COMPANY IS AT YOUR SERVICE allows you to feel at ease, rest assured, and worry-free whether you are selling or buying a house.":
    "我们能够让您的房屋在最短的上市时间内最快、最高价地售出，并且房产的销售形象在市面上也可达到顶级水平。“全公司为您服务”的宗旨，让您不论是卖房还是买房都能舒心、放心、不操心。",
  "Reliability ":
    "信赖度（指企业是否能够始终如一地履行自己对客户所做出的承诺）",
  "Professionalism ": "专业度（企业的人员所具备的专业知识、技能和职业素质）",
  "Visibility ":
    "有形度（有形的服务设施、环境、企业人员的仪表以及服务对客户的帮助和关怀的有形表现）",
  "Responsiveness ":
    "反应度（指企业人员对于客户的需求给予及时回应并能迅速提供服务的愿望）",
  "SERVICE AREA": "服务区域",
  "Nu Stream Realty Toronto focuses on real estate investment, commercial real estate, as well as, strategic marketing planning, investment consulting and real estate licensing training.":
    "多伦多新趋势地产专注于房地产投资、商业地产，以及战略营销规划、投资咨询和房地产许可培训。",
  "The company has successfully established ": "公司已成功组建",
  " teams, providing services in Toronto GTA and surrounding areas. Additionally, our Commercial Department offers a full range of Sales & leasing services for your property.  Also, Nu Stream Commercial provides project marketing services for all of the real estate sectors including industrial, office, retail and residential.":
    "支团队，在多伦多GTA及周边地区提供服务。此外，我们的商务部还为您的财产提供全方位的销售和租赁服务。此外，怒溪商业还为包括工业、办公、零售和住宅在内的所有房地产行业提供项目营销服务。",
  "COMPANY POSITIONING AND UNIQUE MANAGEMENT FEATURES":
    "公司定位和独特的管理特点",
  "Providing real estate services to the global Chinese market.":
    "为全球华人市场提供房地产服务。",
  "Strategic team work collaboration, usage of practical social media platforms, and business Partnership with Lenovo Group China, providing clients with professional services to ensure efficiency and satisfaction for each transaction. Also allowing Realtors to be more professional more focused, to better serve their clients, and to grow rapidly along with outstanding performances and result.":
    "战略性的团队合作，实用社交媒体平台的使用，以及与联想集团中国的业务合作伙伴关系，为客户提供专业的服务，以确保每笔交易的效率和满意度。也让房地产经纪人更专业更专注，更好地服务客户，并伴随着出色的业绩和成果快速成长。",
  "We strive for standardized management, healthy upward culture, immense goals, and highest customer satisfaction possible.":
    "我们追求规范的管理、健康向上的文化、远大的目标和最高的客户满意度。",
  "STRATEGIC COOPERATION": "战略合作",
  "In addition to its continuous growth, Nu Stream Realty places great importance on strategic cooperation with its business partners. At present, Nu Stream Realty is collaborating with numerous real estate social media platforms that hold a large number of investors, providing first-hand information on the local properties to the potential buyers; minimizing the selling period and maximizing the property exposure.":
    "在持续增长的同时，怒溪地产也非常重视与商业伙伴的战略合作。目前，怒溪地产与众多拥有大量投资人的房产社交媒体平台合作，为潜在买家提供本地楼盘的第一手资讯；最小化销售期并最大化财产风险。",
  "OUR TEAM - NU STREAM REALTY (TORONTO) INC., BROKERAGE":
    "地产经纪 我们的团队--新趋势地产，集结多伦多地区金牌地产经纪",
  "NU STREAM AGENTS": "新趋势经纪",
  "Contact our professional Real Estate Agents for a free property consultation.":
    "联系我们的专业房地产经纪人进行免费的房产咨询。",
  " - NU STREAM REALTY (TORONTO) INC., BROKERAGE": " - 多伦多新趋势地产",
  "TEAM MEMBERS": "团队成员",
  "SEND US AN EMAIL": "向我们发送电邮",
  "If you have any questions, please fill out the contact form below and send us a message. We will get back to you within 24-48 hours. Thanks for your patience.":
    "如果您有任何疑问，请填写以下联系我们表格并向我们发送消息。我们将在24小时内与您联系。感谢您的耐心等待。",
  "SEND MESSAGE": "发送电邮",
  "NU STREAM LOCATION": "我们的地址",
  "Find our location below on the Google map. Reach us by getting directions from the Google Map.":
    "您可以从下面的谷歌地图中找到我们的地址。您也可以通过谷歌地图的导航功能来访问我们公司。",
  "LOGIN - NU STREAM REALTY (TORONTO) INC., BROKERAGE":
    "LOGIN - 多伦多新趋势地产",
  "LOGIN INTO YOUR ACCOUNT": "登录您的账户",
  LOGIN: "登录",
  "Email sent": "电邮已发送",
  "CONTACT ME": "联系我",
  "AGENT CENTER - NU STREAM REALTY (TORONTO) INC., BROKERAGE":
    "AGENT CENTER - 多伦多新趋势地产",
  "ADMIN - NU STREAM REALTY (TORONTO) INC., BROKERAGE":
    "系统管理 - 多伦多新趋势地产",
  "Contact With Us": "联系我们",
  Email: "电子邮箱",
  Phone: "电话号码",
  Company: "公司名称",
  "Your Name": "您的名字",
  "Your Email Address": "您的电子邮箱",
  "Your Phone": "您的电话",
  Subject: "邮件主题",
  Message: "邮件内容",
  "Client Review": "客户评价",
  "Client Experience": "客户体验",
  "New Landing": "新移民评价",
  "Client Comment": "客户评价",
  "We moved to Toronto in Aug 2016. We heard of Nu Stream Realty from my friend and tried to contact them at a first time. The agent gave us excellent guidance, unflagging availability, and deep knowledge about a really wide range of homes that we looked at. And we couldn’t have been more satisfied with the process.":
    "因为新趋势地产登陆多伦多的时间并不长，所以我开始也是将信将疑的。不过，我的朋友却一直极力推荐，说新趋势地产的服务与业务水平都是很好的。抱着试试看的态度，我请新趋势地产的北约克团队来帮我进行了免费房屋估价。第一次见面后，我就决定，就是新趋势了！我的经纪非常专业，认真负责。",
  "I contacted with Nu Stream to purchase our first home and really can’t say enough good things about her. They are knowledgeable, personable, endlessly patient, and incredibly generous with her time (including mornings, evenings and weekends over the course of several months).":
    "我是在OpenHouse的时候了解到的新趋势地产。新趋势地产的经纪们认真负责，让我印象非常深刻。在自己要卖房子的时候，我毅然选择了新趋势。他们专业的数据分析能力、认真、诚挚的态度都让我耳目一新，最终房屋以高出我心理预期很多的价位成功售出。",
  "We had a great experience working with NuStream. I couldn’t believe how responsive they were to all of our requests and questions, and they knew far more than we ever hoped to about the process of buying a home. All things went well. Thank you very much.":
    "我刚来多伦多，人生地不熟却急需一套自己的房子。在看到广告后，我找到了新趋势地产。新趋势地产的经纪很敬业，我不了解各个区域，他就挨个给我讲解，从学区、交通等各个角度帮我分析，找出来最适合我的区域，还带我去买车，解决了我的燃眉之急。",
  "Nu Stream helped me buy a home in Mississauga. This was the first time buying a house in Canada. Nu Stream's agent was very patient and helpful, and maintained great communication throughout the entire process. I would highly recommend them to my friends.":
    "选择新趋势地产，也是温哥华朋友的介绍，说新趋势地产的服务是很好的，他在温哥华的几套房产都是让新趋势地产帮助买卖的。第一次见面后，我就觉得他们专业的数据分析能力、认真、诚挚的态度让人放心。除此之外，我的经纪还帮我安排了过渡时期的住房。",
  "Refresh Cache": "刷新缓存",
  "Send Token": "发送令牌",
  "Please enter the Admin Token here!": "请在此处输入管理员令牌！",
  "Login as ADMIN": "以管理员身份登录",
  "WINDSOR BRANCH": "温莎分公司",
  "WINDSOR LEADER": "温莎领队",
  "Website Settings": "网站设置",
  Files: "文件",
  Branches: "分公司",
  "English Banners": "英文Banner",
  "Chinese Banners": "中文Banner",
  Teams: "团队",
  Blacklist: "黑名单",
  SAVE: "保存",
  CANCEL: "取消",
  "UPLOAD FILE": "上传文件",
  "SHOW FILES": "显示文件",
  "DELETE FILE": "删除文件",
  "OPEN FILE": "打开文件",
};

export const translate = (text: string, language: string | undefined) => {
  if (language === "cn") return CN[text] || text;
  return text;
};
