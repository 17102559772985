import React from "react";
import { useParams } from "react-router-dom";
import { translate } from "../languages";
import { API_URL, DESIGNER_EMAIL } from "../consts";

export default function BrochureReOrderForm() {
  const { language } = useParams();
  const [form, setForm] = React.useState<{ [key: string]: any }>({
    SUBJECT: "Brochure Re-Order Form",
  });
  const [status, setStatus] = React.useState("");

  React.useEffect(() => {
    if (
      form["ORDER AGENT NAME"] &&
      form["PROPERTY ADDRESS"] &&
      form["P.S."] &&
      (form["4 PAGES BROCHURE"] ||
        form["12 PAGES BROCHURE"] ||
        form["20 PAGES BROCHURE"]) &&
      ((!form["12 PAGES BROCHURE"] && !form["20 PAGES BROCHURE"]) ||
        form["MLS FORM (CLIENT FULL)"])
    ) {
      setStatus("sendable");
    } else {
      setStatus("");
    }
  }, [form]);

  return (
    <>
      <div className="paper">
        <div className="tag text-capitalize">
          {translate("Brochure Re-Order Form", language)}
        </div>
      </div>
      <div className="paper d-flex flex-column gap-10">
        <div className="row w-100">
          <div className="col col-12 p-3 text-danger fs-14">
            {translate(
              "Due to the increase of production cost(ink, paper etc.), our brochure prices will be updated since Jan 1st, 2019. (New prices: 4 pages $1.5; 12 pages $ 4)",
              language
            )}
          </div>
          <div className="col col-12 p-3 text-danger fs-14">
            {translate(
              "由于楼书成产成本上调（纸张和油墨等），自2019年1月1日起，楼书价格将调整为4页每本$1.5, 12页每本 $4。请各位自行注意。",
              language
            )}
          </div>
          <div className="col col-12 col-lg-6 p-3">
            <label className="form-label">
              {translate("PROPERTY ADDRESS*", language)}
            </label>
            <input
              maxLength={100}
              className="form-control"
              value={form["PROPERTY ADDRESS"] || ""}
              onChange={(e) => {
                setForm({ ...form, "PROPERTY ADDRESS": e.target.value });
              }}
            />
            <div className="text-black-50 fs-14">0 of 100 max characters</div>
          </div>
          <div className="col col-12 col-lg-6 p-3">
            <label className="form-label">
              {translate("ORDER AGENT NAME*", language)}
            </label>
            <input
              maxLength={50}
              className="form-control"
              value={form["ORDER AGENT NAME"] || ""}
              onChange={(e) => {
                setForm({ ...form, "ORDER AGENT NAME": e.target.value });
              }}
            />
            <div className="text-black-50 fs-14">0 of 50 max characters</div>
          </div>
          <div className="col col-12 p-3">
            <label className="form-label">{translate("P.S.*", language)}</label>
            <textarea
              maxLength={200}
              className="form-control"
              value={form["P.S."] || ""}
              onChange={(e) => {
                setForm({
                  ...form,
                  "P.S.": e.target.value,
                });
              }}
            />
            <div className="text-black-50 fs-14">0 of 200 max characters</div>
            <div className="text-decoration-underline text-black-50 fs-14">
              Please list all changes for re-order brochure or input NO CHANGE
              ON DESIGNS
            </div>
          </div>
          <div className="col col-12 p-3">
            <div className="row">
              <div className="col col-12 col-lg-6 p-3">
                <label className="switch align-middle">
                  <input
                    type="checkbox"
                    onChange={(e) => {
                      setForm({
                        ...form,
                        "4 PAGES BROCHURE": e.target.checked ? "" : undefined,
                      });
                    }}
                  />
                  <span className="slider round"></span>
                </label>
                <label className="form-label">
                  {translate("4 PAGES BROCHURE", language)}
                </label>
              </div>
              <div className="col col-12 col-lg-6 p-3">
                {form["4 PAGES BROCHURE"] !== undefined && (
                  <input
                    className="form-control"
                    value={form["4 PAGES BROCHURE"] || ""}
                    placeholder="BROCHURE QUANTITIES (4P)"
                    onChange={(e) => {
                      setForm({
                        ...form,
                        "4 PAGES BROCHURE": e.target.value,
                      });
                    }}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="col col-12 p-3">
            <div className="row">
              <div className="col col-12 col-lg-6 p-3">
                <label className="switch align-middle">
                  <input
                    type="checkbox"
                    onChange={(e) => {
                      setForm({
                        ...form,
                        "12 PAGES BROCHURE": e.target.checked ? "" : undefined,
                      });
                    }}
                  />
                  <span className="slider round"></span>
                </label>
                <label className="form-label">
                  {translate("12 PAGES BROCHURE", language)}
                </label>
              </div>
              <div className="col col-12 col-lg-6 p-3">
                {form["12 PAGES BROCHURE"] !== undefined && (
                  <input
                    className="form-control"
                    value={form["12 PAGES BROCHURE"] || ""}
                    placeholder="BROCHURE QUANTITIES (12P)"
                    onChange={(e) => {
                      setForm({
                        ...form,
                        "12 PAGES BROCHURE": e.target.value,
                      });
                    }}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="col col-12 p-3">
            <div className="row">
              <div className="col col-12 col-lg-6 p-3">
                <label className="switch align-middle">
                  <input
                    type="checkbox"
                    onChange={(e) => {
                      setForm({
                        ...form,
                        "20 PAGES BROCHURE": e.target.checked ? "" : undefined,
                      });
                    }}
                  />
                  <span className="slider round"></span>
                </label>
                <label className="form-label">
                  {translate("20 PAGES BROCHURE", language)}
                </label>
              </div>
              <div className="col col-12 col-lg-6 p-3">
                {form["20 PAGES BROCHURE"] !== undefined && (
                  <input
                    className="form-control"
                    value={form["20 PAGES BROCHURE"] || ""}
                    placeholder="BROCHURE QUANTITIES (20P)"
                    onChange={(e) => {
                      setForm({
                        ...form,
                        "20 PAGES BROCHURE": e.target.value,
                      });
                    }}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="col col-12 p-3">
            <div className="text-black-50 fs-14">
              P.S. Must be same format as last order
            </div>
          </div>
          {(form["12 PAGES BROCHURE"] !== undefined ||
            form["20 PAGES BROCHURE"] !== undefined) && (
            <>
              <div className="col col-12 p-3">
                <label className="form-label">
                  {translate("MLS FORM (CLIENT FULL)*", language)}
                </label>
                <input
                  className="form-control"
                  type="file"
                  accept="application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                  onChange={(e) => {
                    if (e.target.files?.length === 1) {
                      const fileReader = new FileReader();
                      fileReader.onloadend = (e) => {
                        if (e.target?.result) {
                          setForm({
                            ...form,
                            "MLS FORM (CLIENT FULL)": e.target.result as string,
                          });
                        }
                      };
                      fileReader.readAsDataURL(e.target.files[0]);
                    }
                  }}
                />
              </div>
            </>
          )}
          <div className="col col-12 p-3">
            {status === "" && (
              <button
                className="btn-solid"
                style={{ backgroundColor: "#000000" }}
                disabled
              >
                Submit
              </button>
            )}
            {status === "sendable" && (
              <button
                className="btn-solid"
                onClick={() => {
                  setStatus("sending");
                  const to = DESIGNER_EMAIL;
                  const from = "hr@nustreamtoronto.com";
                  const cc = "admin@nustreamtoronto.com";
                  const subject = form["SUBJECT"];
                  const text = JSON.stringify(form);
                  let html =
                    '<table style="width:100%;border: 1px solid;border-collapse: collapse;">';

                  const formProperties = [
                    "SUBJECT",
                    "ORDER AGENT NAME",
                    "PROPERTY ADDRESS",
                    "P.S.",
                    "4 PAGES BROCHURE",
                    "12 PAGES BROCHURE",
                    "20 PAGES BROCHURE",
                    "MLS FORM (CLIENT FULL)",
                  ];

                  for (const formProperty of formProperties) {
                    if (form[formProperty]) {
                      html =
                        html +
                        '<tr><td style="width:40%;border: 1px solid;border-collapse: collapse;">' +
                        formProperty +
                        '</td><td style="width:60%;border: 1px solid;border-collapse: collapse;">' +
                        form[formProperty] +
                        "</td></tr>";
                    }
                  }

                  html = html + "</table>";
                  fetch(`${API_URL}mail`, {
                    method: "POST",
                    headers: {
                      "Content-Type": "application/json",
                      userName: localStorage.getItem("username") || "",
                    },
                    body: JSON.stringify({ from, to, cc, subject, text, html }),
                  })
                    .then((res) => res.json())
                    .then((json) => {
                      if (json.error) {
                        if (json.error.message) alert(json.error.message);
                        setStatus("failed");
                      } else {
                        setStatus("sent");
                      }
                    });
                }}
              >
                {translate("Submit", language)}
              </button>
            )}
            {status === "sending" && (
              <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            )}
            {status === "sent" && (
              <div className="alert alert-primary" role="alert">
                {translate("Form submited", language)}
              </div>
            )}
            {status === "failed" && (
              <div className="alert alert-danger" role="alert">
                {translate("Form submit failed, please try later!", language)}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
